import {
    Box,
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, Radio,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
export default function RollbackResponseDialog({response, open, setOpen}) {

    return (
        <Dialog onClose={() => setOpen(false)} open={open}>
            <DialogTitle>Mass rollback result</DialogTitle>

            <IconButton
                aria-label="close"
                onClick={() => setOpen(false)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon/>
            </IconButton>
            <DialogContent>
                <Table>
                    {response.map(item =>
                        <TableRow selected={item.error}>
                            <TableCell>{item.environment}</TableCell>
                            <TableCell>{item.message}</TableCell><TableCell>{item.error==false ? <CheckCircleIcon /> : <ErrorIcon /> }</TableCell>
                        </TableRow>)}
                </Table>
            </DialogContent>
            <DialogActions>


                <Button variant="outlined"
                        onClick={() => setOpen(false)}>
                    Close
                </Button>

            </DialogActions>
        </Dialog>
    );
}