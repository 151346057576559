import api from "../api/base-api"
import {hasContent} from "../../components/Utils/CommonUtils";

const campaignTypeApi = api.injectEndpoints({
        endpoints: (build) => ({
            getAllCampaignTypes: build.query({
                query: () => '/admin/type/getAll',
                providesTags: ['campaignTypes']
            }),

            saveCampaignType: build.mutation({
                query: (body) => {
                    return {
                        url: `/admin/type/add`,
                        method: "POST",
                        body: body
                    }
                },
                invalidatesTags: ['campaignTypes','tournaments']
            })
        }),
    }
)

export const {
    useGetAllCampaignTypesQuery,
    useSaveCampaignTypeMutation,
} = campaignTypeApi;