import {createContext, useEffect, useState} from "react";
import {Box, Snackbar,Alert} from "@mui/material";

export const SnackBarContext = createContext()
const AUTO_DISMISS = 3500

export function SnackBarProvider({children}) {
    const [alerts, setAlerts] = useState([])

    const activeAlertIds = alerts.join(',')

    useEffect(() => {
        if (activeAlertIds.length > 0) {
            const timer = setTimeout(() => setAlerts((alerts) => alerts.slice(0, alerts.length - 1)), AUTO_DISMISS)
            return () => clearTimeout(timer)
        }
    }, [activeAlertIds])

    const addAlert = (alert) => {
        if (alerts.length >= 3) {
            const updatedAlerts = [...alerts.slice(1), alert];
            setAlerts(updatedAlerts);
        } else {
            setAlerts((prevAlerts) => [alert, ...prevAlerts]);
        }
    }
    const value = {addAlert}

    return (
        <SnackBarContext.Provider value={value}>
            {children}
            <div className={"snackbar-wrapper"}>
                {alerts.map((alert, index) =>
                    <Snackbar open={true} key={index}
                              anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}>
                        <Alert variant="filled" severity={alert.type} >
                            {alert.text}
                        </Alert>
                    </Snackbar>)}
            </div>
        </SnackBarContext.Provider>
    )
}