import api from "../api/base-api"

const campaignApi = api.injectEndpoints({
        endpoints: (build) => ({

            getTransferStatus: build.query({
                query: (data) => {
                    return {
                        'url': '/admin/transfer/get',
                        env:data.env
                    }
                },
            }),
            getAllParams: build.query({
                query: () => '/admin/parameter/getAll',
            }),

            getAllBundles: build.query({
                query: (ev) => `/admin/bundle/getAll?environment=${ev}`,
                keepUnusedDataFor: 0
            }),
            getBundleHistory: build.query({
                query: (data) => {
                    return {
                        'url': `/admin/bundle/history?environment=${data.env}&bundle=${data.bundle}`,
                        env:data.env
                    }
                },
                keepUnusedDataFor: 0
            }),
            getCampaigns: build.query({
                query: (data) => {
                    return {
                        url: `/admin/campaign/search?environment=${data.env}&id=${data.id}`,
                        env: data.env
                    }
                },
            }),
            findCampaignById: build.query({
                query: (data) => `/admin/campaign/select?environment=${data.ev}&id=${data.id}`,
                providesTags: (result, error, arg) => {
                    return result
                        ? [{type: 'Campaign', id:result.id}, 'Campaign']
                        : ['Campaign']
                }
            }),
            getAllCampaigns: build.query({
                query: (data) => {
                    let query = '';
                    if(data.hasOwnProperty('from')) {
                        query = `&from=${data.from}&till=${data.till}`
                    }
                    return {
                        url: `/admin/publisher/context?environment=${data.env}`+query,
                        env: data.env
                    }
                },
                keepUnusedDataFor: 0,
                providesTags: ['campaignsList'],

            }),
            saveCampaign: build.mutation({
                query: (data) => {
                    const {ev, ...body} = data
                    return {
                        url: `/admin/campaign/save?environment=${ev}`,
                        method: "POST",
                        body: body.body,
                        env: body.env
                    }
                },
                invalidatesTags: ['campaignsList','Campaign']

            }),
            transfer: build.mutation({
                query: (data) => {
                    const {environment, source, campaign, env} = data

                    return {
                        url: `/admin/transfer/transfer?environment=${environment}&source=${source}&campaign=${campaign}`,
                        method: "POST",
                        body: data,
                        env: env
                    }
                },
                invalidatesTags: ['campaignsList']

            }),
            getDiff: build.query({
                query: (data) => {
                    const {environment, source, campaign, env} = data
                    return {
                        url: `/admin/transfer/bundle/diff?environment=${environment}&source=${source}&campaign=${campaign}`,
                        env: env
                    }
                },
                keepUnusedDataFor: 0
            }),
            getPlatformsInfo: build.query({
                query: (data) => {
                    const {env, campaign} = data;
                    return {
                        url: `/admin/bundle/check?environment=${env}&campaign=${campaign}`,
                        env: env,
                        method: "GET"
                    }
                },
                keepUnusedDataFor: 0

            }),
            setStatus: build.mutation({
                query: (data) => {
                    const {environment, status, id} = data

                    return {
                        url: `/admin/campaign/status?environment=${environment}&id=${id}&status=${status}`,
                        method: "POST",
                        body: data,
                        env: data.environment
                    }
                }
            }),
            checkCampaignTransferStatus: build.query({
                query: (data) => {

                    return {
                        url: `/admin/transfer/check?transfer_id=${data.transferId}`,
                        method: "GET",
                        env:data.env
                    }
                }
            }),

            rollbackBundle: build.mutation({
                query: (data) => {
                    const {environment, bundle, generation, version} = data

                    return {
                        url: `/admin/bundle/rollback?environment=${environment}&bundle=${bundle}&generation=${generation}&version=${version}`,
                        method: "POST",
                        body: data,
                        env: environment
                    }
                }
            }),
            rollbackAllBundle: build.mutation({
                query: (data) => {
                    const {bundle, generation, version} = data

                    return {
                        url: `/admin/bundle/rollback/all?bundle=${bundle}&generation=${generation}&version=${version}`,
                        method: "POST",
                        body: data,
                    }
                }
            }),
        }),
    }
)

export const {

    useGetDiffQuery,
    useGetTransferStatusQuery,
    useGetAllParamsQuery,
    useGetAllBundlesQuery,
    useGetBundleHistoryQuery,
    useGetCampaignsQuery,
    useGetAllCampaignsQuery,
    useFindCampaignByIdQuery,
    useRollbackBundleMutation,
    useRollbackAllBundleMutation,
    useSaveCampaignMutation,
    useTransferMutation,
    useSetStatusMutation,
    useGetPlatformsInfoQuery,
    useCheckCampaignTransferStatusQuery
} = campaignApi;