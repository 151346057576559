import {Fragment, useEffect, useState} from "react";
import {FormHelperText, Grid, MenuItem, TextField, Typography} from "@mui/material";
import BasicDateTimePicker from "../../Utils/BasicDateTimePicker";
import dayjs from "dayjs";

export const timeZoneOffset = {
    displayName: "GMT+3 (Europe/Moscow)",
    value: "+03:00"
};
export default function DateStep(props) {
    const [isDistributionTypeFixed, setIsDistributionTypeFixed] = useState(false);

    const distributionTypes = [
        {
            displayName: "FIXED",
            value: "fixed"
        },
        {
            displayName: "PERMANENT",
            value: "permanent"
        },
        {
            displayName: "FLOAT",
            value: "float"
        }
    ]

    useEffect(() => {

        if (props.currentCampaign.distributionType === 'fixed' || props.currentCampaign.distributionType === 'permanent') {
            setIsDistributionTypeFixed(true)
            let newErrorFields = new Set(props.errorFields);
            newErrorFields.delete("durationHour");
            props.setErrorFeilds(newErrorFields);
        } else {
            setIsDistributionTypeFixed(false)
        }

        const newCampaign = {...props.currentCampaign};
        newCampaign.announceDurationTime = dayjs(props.currentCampaign.from.unix() * 1000 - props.typeParameters.announceDuration * 1000);
        newCampaign.extraDurationTime = dayjs(props.currentCampaign.till.unix() * 1000 + props.typeParameters.extraDuration * 1000);
        props.setCurrentCampaign(newCampaign);

    }, [props.currentCampaign.distributionType, props.currentCampaign.from, props.currentCampaign.till])


    const getNextEventDates = () => {
        let startDateMs = Date.parse(props.currentCampaign.from);
        let endDateMs = Date.parse(props.currentCampaign.schedule.till);
        let repeatPeriodDays = props.currentCampaign.schedule.delayDays;
        if(repeatPeriodDays == 0 || repeatPeriodDays=='') return false
        const tillMs = Date.parse(props.currentCampaign.till); // till из currentCampaign

        const dayInMs = 24 * 60 * 60 * 1000; // Миллисекунд в одном дне
        const repeatPeriodMs = repeatPeriodDays * dayInMs; // Период повторяемости в миллисекундах

        const utc = require('dayjs/plugin/utc'); // Подключение плагина для работы с UTC
        const timezone = require('dayjs/plugin/timezone'); // Подключение плагина для работы с часовыми поясами
        dayjs.extend(utc);
        dayjs.extend(timezone);

        const timeZone = 'Europe/Moscow'; // Часовой пояс GMT+3

        // Первая следующая дата после стартовой
        let nextEventDate1Ms = startDateMs + repeatPeriodMs;
        let nextEventDate1 = dayjs(nextEventDate1Ms).tz(timeZone).format('YYYY-MM-DD HH:mm:ss');
        let nextEventDate1Pair = dayjs(tillMs + (nextEventDate1Ms - startDateMs)).tz(timeZone).format('YYYY-MM-DD HH:mm:ss');
        //
        // Вторая следующая дата после первой
        let nextEventDate2Ms = startDateMs + 2 * repeatPeriodMs;
        let nextEventDate2 = nextEventDate2Ms <= endDateMs||(isNaN(endDateMs)) ? dayjs(nextEventDate2Ms).tz(timeZone).format('YYYY-MM-DD HH:mm:ss') : null;
        let nextEventDate2Pair = nextEventDate2 ? dayjs(tillMs + (nextEventDate2Ms - startDateMs)).tz(timeZone).format('YYYY-MM-DD HH:mm:ss') : null
        // Вычисляем последнюю дату перед endDateMs
        let lastEventDateMs = startDateMs;
        while (lastEventDateMs + repeatPeriodMs <= endDateMs) {
            lastEventDateMs += repeatPeriodMs;
        }

        // Последняя возможная дата события
        let lastEventDate = dayjs(lastEventDateMs).tz(timeZone).format('YYYY-MM-DD HH:mm:ss');
        let lastEventDatePair = dayjs(tillMs + (lastEventDateMs - startDateMs)).tz(timeZone).format('YYYY-MM-DD HH:mm:ss');

        return {
            nextEventDate1: { date: nextEventDate1, pair: nextEventDate1Pair },
            nextEventDate2: nextEventDate2 ? { date: nextEventDate2, pair: nextEventDate2Pair } : "No more events before the end date",
            lastEventDate: { date: lastEventDate, pair: lastEventDatePair }
        };
    }

    return (
        <Fragment>

            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <BasicDateTimePicker
                        label="From (GMT+3)"
                        name="from"
                        value={props.currentCampaign.from}
                        onChange={props.handleDateChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <BasicDateTimePicker
                        label="Till (GMT+3)"
                        name="till"
                        value={props.currentCampaign.till}
                        onChange={props.handleDateChange}
                        disabled={props.currentCampaign.distributionType=='permanent'}
                    />
                    {props.errorFields.has('date') &&
                        <FormHelperText style={{color: "red"}}>The end date must be after the start date</FormHelperText>}
                </Grid>
                <Grid item xs={6}>
                    <BasicDateTimePicker
                        disabled={true}
                        label="Announce From (GMT+3)"
                        name="announceDuration"
                        value={dayjs(props.currentCampaign.from.unix() * 1000 - props.typeParameters.announceDuration * 1000)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <BasicDateTimePicker
                        disabled={true}
                        label="Extra Time Till (GMT+3)"
                        name="extraDuration"
                        value={dayjs(props.currentCampaign.till.unix() * 1000 + props.typeParameters.extraDuration * 1000)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        id="distributionType"
                        name="distributionType"
                        label="Distribution Type"
                        error={props.errorFields.has('distributionType')}
                        select
                        fullWidth
                        value={props.currentCampaign.distributionType}
                        onChange={props.handleChange}
                    >
                        {distributionTypes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.displayName}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required={!isDistributionTypeFixed}
                        id="durationHour"
                        name="durationHour"
                        label="Duration (Hours)"
                        disabled={isDistributionTypeFixed}
                        fullWidth
                        variant="outlined"
                        error={props.errorFields.has('durationHour')}
                        value={props.currentCampaign.durationHour}
                        onChange={props.handleChange}
                    />
                </Grid>
            </Grid>
            {props.template&&<><Grid item xs={12}>
                <h2>Template schedule</h2>
                <TextField
                    required
                    id="delayDays"
                    name="delayDays"
                    label="Delay Days"
                    error={props.errorFields.has('delayDays')}
                    value={props.currentCampaign.schedule.delayDays}
                    fullWidth
                    variant="outlined"
                    onChange={props.handleChangeSchedule}
                /></Grid>

                {props.currentCampaign.schedule.delayDays>0&&props.currentCampaign.schedule.delayDays!=''&&<Typography sx={{pb:2}} style={{color:"grey"}}>
                    Next campaign example: <br />
                    {getNextEventDates().nextEventDate1.date} - {getNextEventDates().nextEventDate1.pair}<br />
                    {getNextEventDates().nextEventDate2.date!=null&&<>{getNextEventDates().nextEventDate2.date} - {getNextEventDates().nextEventDate2.pair}<br />
                    ...</>}
                </Typography>}


                <Grid item xs={12}>
                    <BasicDateTimePicker
                        label="Till (GMT+3)"
                        name="till"
                        value={props.currentCampaign.schedule.till}
                        onChange={props.handleChangeScheduleTill}
                        minDate={dayjs(props.currentCampaign.from).add(props.currentCampaign.schedule.delayDays, 'day')}
                    />
                    {props.errorFields.has('scheduletill') &&
                        <FormHelperText style={{color: "red"}}>The TILL schedule date must be after the start date</FormHelperText>}

                    {props.currentCampaign.schedule.till!==null&&props.currentCampaign.schedule.delayDays!=''&&props.currentCampaign.schedule.delayDays>0&&<Typography sx={{pb:2}} style={{color:"grey"}}>
                        Last campaign dates: <br />
                        {getNextEventDates().lastEventDate.date} - {getNextEventDates().lastEventDate.pair}
                    </Typography>}
                </Grid></>}
        </Fragment>
    )
}