import {
    Box,
    Button, Checkbox,
    Container, debounce,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {useCallback, useState} from "react";
import ModalComponent from "./ModalComponent";
import {useGetAllCampaignTypesQuery} from "../../../core/reducers/campaignType";
import EditIcon from "@mui/icons-material/Edit";
import Loading from "../../Loading";
import {DataGrid} from "@mui/x-data-grid";
import {getEnvName, isAdmin, isProdAdmin, isStageAdmin} from "../../Utils/CommonUtils";
import IconButton from "@mui/material/IconButton";
import {useSelector} from "react-redux";

import {useSearchParams} from "react-router-dom";

export default function CampaignType() {
    const userSession = useSelector((state) => state.data.UserSession);
    const [searchParams] = useSearchParams();
    const sourceMode = useSelector((state) => state.data.SourceMode);
    const envName = getEnvName('env', sourceMode, searchParams);

    const getNewCampaignType = () => {
        return {
            id: "",
            readableType: "",
            contentType: "",
            interactionType: "",
            limitLateDistribution: "",
            lateDistributionPeriod: "",
            announceDuration: "",
            extraDuration: "",
            metaData: {
                abbreviation: "",
                link: ""
            },
            matchmakingTypes: []
        }
    }

    const [createCampaignType, setCreateCampaignType] = useState(false);
    const [updateCampaignType, setUpdateCampaignType] = useState(false);
    const [currentCampaignType, setCurrentCampaignType] = useState(getNewCampaignType());
    const [errorMessage, setErrorMessage] = useState('');
    const {
        data: types,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error
    } = useGetAllCampaignTypesQuery()


    const handleCreateCampaignType = () => {
        setCurrentCampaignType({
            ...currentCampaignType,
            limitLateDistribution: false,
            lateDistributionPeriod:0,
        })
        setCreateCampaignType(true);
    }

    const handleEditCampaignType = (campaignType) => {
        let newCampaignType = {...types[campaignType]}
        newCampaignType.announceDuration = newCampaignType.announceDuration / 3600
        newCampaignType.extraDuration = newCampaignType.extraDuration / 3600
        setCurrentCampaignType(newCampaignType)
        setUpdateCampaignType(true);
    }

    const handleDialogClose = () => {
        setCreateCampaignType(false);
        setUpdateCampaignType(false);
        setCurrentCampaignType(getNewCampaignType);
        setErrorMessage('')
    }


    const [columnWidths, setColumnWidths] = useState({
        id: parseInt(localStorage.getItem('w_id')) || 200,
        contentType: parseInt(localStorage.getItem('w_contentType')) || 150,
        interactionType: parseInt(localStorage.getItem('w_interactionType')) || 150,
        regexp: parseInt(localStorage.getItem('w_regexp')) || 200,
        limitLateDistribution: parseInt(localStorage.getItem('w_limitLateDistribution')) || 130,
        lateDistributionPeriod: parseInt(localStorage.getItem('w_lateDistributionPeriod')) || 120,
        announceDuration: parseInt(localStorage.getItem('w_announceDuration')) || 150,
        extraDuration: parseInt(localStorage.getItem('w_extraDuration')) || 150,
        abbreviation: parseInt(localStorage.getItem('w_abbreviation')) || 70,
        link: parseInt(localStorage.getItem('w_link')) || 50
    });

    // Debounced function to store column widths in localStorage
    const updateLocalStorage = useCallback(
        debounce((field, width) => {
            debugger
            localStorage.setItem(`w_${field}`, width);
        }, 300), []
    );


    const columns = [
        { field: 'id', headerName: 'ID', width: columnWidths['id'] },
        { field: 'contentType', headerName: 'Content Type', width: columnWidths['contentType'] },
        { field: 'interactionType', headerName: 'Interaction Type', width: columnWidths['interactionType'] },
        { field: 'regexp', headerName: 'Validation Regexp', width: columnWidths['regexp']},

        {
            field: 'limitLateDistribution',
            headerName: 'Limit Late Distribution',
            width: columnWidths['limitLateDistribution'],
            renderCell: (params) => (
                <Checkbox checked={params.value} disabled />
            ),
            sortable: true,
            sortComparator: (v1, v2) => v1.localeCompare(v2, undefined, { numeric: true, sensitivity: 'base' }),
        },
        { field: 'lateDistributionPeriod', headerName: 'Late Distribution Period (seconds)', width: columnWidths['lateDistributionPeriod'], sortable: true,
            sortComparator: (v1, v2) => v1.localeCompare(v2, undefined, { numeric: true, sensitivity: 'base' }),
        },
        { field: 'announceDuration', headerName: 'Announce From (hours)', width: columnWidths['announceDuration'],
            sortable: true,
            sortComparator: (v1, v2) => v1.localeCompare(v2, undefined, { numeric: true, sensitivity: 'base' }),
        },
        { field: 'extraDuration', headerName: 'Extra Time Till (hours)', width: columnWidths['extraDuration'],
            sortable: true,
            sortComparator: (v1, v2) => v1.localeCompare(v2, undefined, { numeric: true, sensitivity: 'base' }),
        },
        { field: 'abbreviation', headerName: 'Code', width: columnWidths['abbreviation'],
            renderCell: (params) => {
                return <>{params.row?.abbreviation}</>
            },
        },
        { field: 'link', headerName: 'Doc', width: columnWidths['link'],
            renderCell: (params) => {
               return (params.row.link != null)?
                    <a href={params.row.link} target="_blank" rel="noopener noreferrer">link</a> :
                    <></>
            },
        },

        //{ field: 'readableType', headerName: 'Readable Type', width: 150 },
        //{ field: 'version', headerName: 'Version', width: 100 },


    ];
    //isAdmin(userSession)
    if(true) columns.push({
        field: 'action',
        headerName: 'Action',
        width: 70,
        renderCell: (params) => {
            // Assuming isAdmin is available in the component's scope
            return true||isAdmin(userSession) ? (
                <IconButton
                    aria-label="edit"
                    onClick={() => handleEditCampaignType(params.row.id)}
                >
                    <EditIcon />
                </IconButton>
            ) : null;
        },
        sortable: false,
        filterable: false,
    })

    const rows = types==undefined ? null : Object.values(types).map(item => ({
        id: item.id,
        readableType: item.readableType,
        contentType: item.contentType,
        interactionType: item.interactionType,
        regexp: item.regexp,
        //version: item.defaultGenerationClientVersion?.version,
        limitLateDistribution: item.limitLateDistribution.toString() === "true",
        lateDistributionPeriod: item.lateDistributionPeriod +'s',
        announceDuration: item.announceDuration && item.announceDuration !== 0 ? item.announceDuration / 3600 + 'h' : '0h',
        extraDuration: item.extraDuration && item.extraDuration !== 0 ? item.extraDuration / 3600 +'h' : '0h',
        link: item.metaData !== null &&  item.metaData.link !== null && item.metaData.link.trim() !== '' ? item.metaData.link : null,
        abbreviation: item.metaData !== null &&  item.metaData?.abbreviation !== null ? item.metaData?.abbreviation : ''

    }));

    const tableHeader = () => {
        return columns
            .map(i => (
                <TableCell align="center">
                    <Typography align="center">{i}</Typography>
                </TableCell>
            ))
    }


    const handleColumnResize = useCallback((params) => {
        const { width } = params;
        const field = params.colDef.field
        debugger
        // Update the width in state
        setColumnWidths((prev) => ({
            ...prev,
            [field]: width,
        }));
        // Save the new width to localStorage, but debounced to avoid frequent updates
        updateLocalStorage(field, width);
    }, [updateLocalStorage]);

    return (
        <>
            <Container maxWidth={"100%"} sx={{pl:0}} style={{  height: '80vh'}}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <h1>Campaign type management</h1>

                    <Button variant="contained"
                            onClick={() => handleCreateCampaignType()}
                    >
                        Create campaign type
                    </Button>
                </Box>
                <Box pt={0} style={{ flexGrow: 1 }}>
                    <TableContainer component={Paper}>
                        <Loading isLoading={isLoading} isError={isError} error={error}>
                        </Loading>

                            { types != undefined && <DataGrid
                                columnHeaderHeight={90}
                                style={{ height: '75vh' }}
                                disableVirtualization
                                rows={rows}
                                onColumnResize={handleColumnResize}

                                columns={columns}
                                pageSize={100}
                                // sortModel={[
                                //     {
                                //         field: 'id',
                                //         sort: 'asc', // Change to 'desc' if you want descending order
                                //     },
                                // ]}
                            />}


                </TableContainer>
                </Box>
                {(createCampaignType || updateCampaignType) &&
                    <ModalComponent
                        title={updateCampaignType ? 'Update Campaign Type' : 'Create Campaign Type'}
                        setCurrentCampaignType={setCurrentCampaignType}
                        open={createCampaignType || updateCampaignType}
                        handleDialogClose={() => handleDialogClose()}
                        currentCampaignType={currentCampaignType}
                        update={updateCampaignType}
                        setErrorMessage={setErrorMessage}
                        errorMessage={errorMessage}
                        types={types}

                />}
            </Container>
        </>
    )

}