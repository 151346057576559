import api from "../api/base-api"

const campaignTypeApi = api.injectEndpoints({
        endpoints: (build) => ({
            getAllUserGroups: build.query({
                query: (data) => {
                    return {
                        'url': '/admin/userGroup/groups',
                        env:data.env
                    }
                },
                transformResponse(data) {
                    return data.sort();
                },
                providesTags: ['userGroups']
            }),
            getAllGroupsForUser: build.query({
                query: (data) => {
                    return {
                        'url': `/admin/userGroup/user?userId=${data.userId}`,
                        env:data.env
                    }
                },
            }),
            getAllUserGroupUsers: build.query({
                query: (data) => {
                    return {
                        'url': `/admin/userGroup/group?groupId=${data.groupId}`,
                        env:data.env
                    }
                },
                providesTags: ['userGroupUsers']
            }),
            getAllUserGroupsInfo: build.query({
                query: (data) => {
                    return {
                        'url': `/admin/userGroup/groups/info`,
                        env:data.env
                    }
                },
                providesTags: ['userGroups']
            }),
            saveUserGroup: build.mutation({
                query: (data) => {
                    return {
                        url: `/admin/userGroup/add?groupId=${data.groupId}`,
                        body: data.body,
                        method: "POST",
                    }
                },
                invalidatesTags: ['userGroupUsers', 'userGroups']
            }),
            deleteUsersFromGroup: build.mutation({
                query: (data) => {
                    return {
                        url: `/admin/userGroup/remove?groupId=${data.groupId}`,
                        body: data.body,
                        method: "POST",
                    }
                },
                invalidatesTags: ['userGroupUsers', 'userGroups']
            })
        }),
    }
)

export const {
    useGetAllUserGroupsQuery,
    useGetAllGroupsForUserQuery,
    useGetAllUserGroupUsersQuery,
    useGetAllUserGroupsInfoQuery,
    useSaveUserGroupMutation,
    useDeleteUsersFromGroupMutation,
} = campaignTypeApi;