import api from "../api/base-api"

const campaignTypeApi = api.injectEndpoints({
        endpoints: (build) => ({
            getAllEnvironments: build.query({
                query: () => '/admin/environment/getAll',
                providesTags: ['environments']
            }),
            saveEnvironment: build.mutation({
                query: (data) => {
                    let allowedSources = data.allowedSources ? data.allowedSources.map(i => `&allowedSources=${i}`).join('') : ""
                    return {
                        url: `/admin/environment/add?name=${data.name}&reserved=${data.reserved}&reservedPriority=${data.reservedPriority}${allowedSources}&hasCreator=${data.hasCreator}`,
                        method: "POST",
                    }
                },
                invalidatesTags: ['environments']
            }),
            removeEnvironment: build.mutation({
                query: (name) => {
                    return {
                        url: `/admin/environment/remove?environment=${name}`,
                        method: "GET",
                    }
                },
                invalidatesTags: ['environments']
            })
        }),
    }
)

export const {
    useGetAllEnvironmentsQuery,
    useSaveEnvironmentMutation,
    useRemoveEnvironmentMutation,
} = campaignTypeApi;