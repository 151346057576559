import {
    Box,
    Button, Chip,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {useGetPlatformsInfoQuery} from "../../core/reducers/campaign";
import Loading from "../Loading";
import clipboardCopy from "clipboard-copy";
import dayjs from "dayjs";
import {isAdmin, isProdAdmin, isProdEnv, LIVEOPS_ENV, PROD} from "../Utils/CommonUtils";
import {useSelector} from "react-redux";

export default function CampaignBundleDialog({item, open, setOpen, campaign, env, selectBundle, fixedBundle}) {

    const userSession = useSelector((state) => state.data.UserSession);

    const platformInfo = useGetPlatformsInfoQuery({env, campaign}, {skip: !open});

    const formatdate = (timestamp) => {
        if(timestamp == null) return ''
        let d = dayjs(timestamp * 1000).utcOffset(180).format('YYYY-MM-DD HH:mm:ss')
        return d;
    }

    const renderPlatforms = (generationNumber) => {
        const platforms = platformInfo.data.generations.filter(g => g.generation.generation === parseInt(generationNumber))
        if(!platforms || platforms.length == 0) return '';
        const platforms_item = platforms[0].platforms
        return Object.keys(platforms_item).map(key => {
            return <Box>{key} : {platforms_item[key].toString()}</Box>
        })
    }

    const sortedGenerations = platformInfo.data!=undefined ? [...platformInfo.data.generations].sort((a, b) => b.generation.generation - a.generation.generation) : null;

    const showRollback = () => {
        if(env == PROD) return false
        return isAdmin(userSession)
    }

    return (
        <Dialog
            onClose={() => setOpen(false)} open={open}
            maxWidth={'xl'}
        >
            <DialogTitle>Bundles {fixedBundle&&<Chip label="fixedBundle" />}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => setOpen(false)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon/>
            </IconButton>
            <DialogContent>
                <Loading isLoading={platformInfo.isLoading} isError={platformInfo.isError} error={platformInfo.error}>
                    {platformInfo.data != undefined &&
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Generation</TableCell>
                                    <TableCell>Client version</TableCell>
                                    <TableCell>Bundle version</TableCell>
                                    <TableCell>Branches</TableCell>
                                    <TableCell>Platforms</TableCell>
                                    <TableCell>Created At</TableCell>
                                    {showRollback()&&<TableCell>History</TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedGenerations
                                    .map((item, key) => (

                                    <TableRow key={key}>
                                        <TableCell>G{item.generation.generation}</TableCell>
                                        <TableCell>{item.generation['clientVersion']['version']}</TableCell>
                                        <TableCell>{item.generation['bundleVersion']}</TableCell>
                                        <TableCell>
                                            <>
                                                <div>Main: {item.generation?.data?.repositoryBranch}</div>
                                                <div>Content: {item.generation?.data?.contentBranch}</div>
                                            </>
                                        </TableCell>
                                        <TableCell>{Object.keys(item.platforms).map(key => {
                                            return <Box>{key} : {item.platforms[key].toString()}</Box>
                                        })}</TableCell>
                                        <TableCell>{formatdate(item.generation?.data?.date)}</TableCell>
                                        {showRollback()&&<TableCell>
                                           <Button
                                                variant="outlined"
                                                onClick={() => {
                                                    selectBundle(key, item)
                                                }}>
                                                Rollback
                                            </Button>
                                        </TableCell>}
                                    </TableRow>

                                ))}
                            </TableBody>
                        </Table>
                    }

                </Loading>
            </DialogContent>
            <DialogActions>

                <Button variant="contained"
                        onClick={() => setOpen(false)}>
                    Close
                </Button>

            </DialogActions>
        </Dialog>
    );
}
