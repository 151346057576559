import {
    Alert,
    Box,
    Button,
    Card,
    Container,
    Grid,
    IconButton,
    InputAdornment,
    List,
    ListItemButton, MenuItem,
    Snackbar,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import {useEffect, useRef, useState} from "react";
import {useGetCampaignsQuery} from "../../core/reducers/campaign";
import {Link, useSearchParams} from "react-router-dom";
import {
    deleteHistory,
    getEnvName, getFromStorage,
    getHistoryFromStorage, setSearchType, sortAlphabeticallyByOneField
} from "../Utils/CommonUtils";
import {useDispatch, useSelector} from "react-redux";
import {AbTestConfigs, CampaignConfigs, SEARCH_TYPES, TemplatesConfigs} from "../../core/reducers/typeFilter";
import { v4 as uuid } from "uuid";
import {useSearchAbTestsQuery} from "../../core/reducers/abtest";
import mode from "../../core/reducers/mode";
import {useGetTemplatesQuery} from "../../core/reducers/templates";

export default function Search() {

    const [isOpenSnackBar, setOpenSnackBar] = useState(false);
    const [isOpenSnackBarAbTests, setOpenSnackBarAbTests] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const sourceMode = useSelector((state) => state.data.SourceMode);
    const q = searchParams.get("q");
    const [currentSearchValue, setCurrentSearchValue] = useState(q ? q : "");
    const ev = getEnvName('env', sourceMode, searchParams);
    const [historyChanged, setHistoryChanged] = useState(false);
    const [currentType, setCurrentType] = useState(getFromStorage('searchType') ? getFromStorage('searchType') : 'All');
    const [previousEdits, setPreviousEdits] = useState(getHistoryFromStorage(ev, currentType))
    const dispatch = useDispatch();
    const [itemsForDisplay, setItemsForDisplay] = useState([]);


    let campaigns = useGetCampaignsQuery({
        env: ev,
        id: q
    }, {skip: (!q || (currentType === SEARCH_TYPES.AB_TESTS || currentType === SEARCH_TYPES.TEMPLATES))});

    let abTests = useSearchAbTestsQuery({
        env: ev,
        id: q
    }, {skip: (!q || currentType === SEARCH_TYPES.CAMPAIGNS || currentType === SEARCH_TYPES.TEMPLATES)});

    let templates = useGetTemplatesQuery({
        env: ev,
        id: q
    }, {skip: (!q || currentType === SEARCH_TYPES.CAMPAIGNS || currentType === SEARCH_TYPES.AB_TESTS )});

    useEffect(() => {
        let items = [];
        if (campaigns.status === 'fulfilled' && campaigns.data?.length !== 0) {
            items = items.concat(campaigns.data.map(c => {
                return new CampaignConfigs(c);
            }));
        }

        if (abTests.status === 'fulfilled' && abTests.data?.length !== 0) {
            items = items.concat(abTests.data.map(a => {
                return new AbTestConfigs(a);
            }));
        }

        if (templates.status === 'fulfilled' && templates.data?.length !== 0) {
            items = items.concat(templates.data.map(a => {
                return new TemplatesConfigs(a);
            }));
        }

        sortAlphabeticallyByOneField(items, 'id');
        setItemsForDisplay(items.reverse());

    }, [campaigns, abTests, templates])


    useEffect(() => {
        if (templates.status === 'fulfilled' && templates.data?.length === 0 && currentType === SEARCH_TYPES.TEMPLATES) {
            handleOpenSnackBar()
        }
    }, [templates])

    useEffect(() => {
        if (campaigns.status === 'fulfilled' && campaigns.data?.length === 0 && currentType === SEARCH_TYPES.CAMPAIGNS) {
            handleOpenSnackBar()
        }
    }, [campaigns])

    useEffect(() => {
        if (abTests.status === 'fulfilled' && abTests.data?.length === 0 && currentType === SEARCH_TYPES.AB_TESTS) {
            handleOpenSnackBarAbTests()
        }
    }, [abTests])

    useEffect(() => {
        setPreviousEdits(getHistoryFromStorage(ev, currentType));
    }, [ev, historyChanged, currentType])


    const handleSearch = () => {
        searchParams.set('q', currentSearchValue)
        setSearchParams(searchParams)
    }

    const handleOpenSnackBar = () => {
        setOpenSnackBar(true)
    }

    const handleOpenSnackBarAbTests = () => {
        setOpenSnackBarAbTests(true)
    }

    const handleCloseSnackBar = () => {
        setOpenSnackBar(false)
    }
    const handleCloseSnackBarAbTests = () => {
        setOpenSnackBarAbTests(false)
    }

    const handleClearButton = () => {
        setSearchParams({q: '', 'env': ev})
        setCurrentSearchValue('')
    }

    const handleHistoryClear = () => {
        setHistoryChanged(!historyChanged);
        deleteHistory(ev)
    }

    const handleTypeOnChange = (e) => {
        setCurrentType(e.target.value);
        setSearchType(e.target.value, dispatch)
    }

    const getSearchItem = (item, prevEdits, index) => {
        if (index) {
            return prevEdits[prevEdits.length - index - 1];
        }
        return item
    }

    const getItemButtonComponent = (item, prevEdits, index) => {
        let searchItem = getSearchItem(item, prevEdits, index)

        return <ListItemButton
            key={uuid()}
            component={Link}
            to={`/creator/${searchItem.urlPath}/${searchItem.id}?env=${ev}`}>
            <Grid container>
                <Grid item sm={2.5} style={{color: searchItem.color}}>
                    {searchItem.type}
                </Grid>
                <Grid item sm={9.5}>
                    {searchItem.id}
                </Grid>
            </Grid>
        </ListItemButton>
    }

    return (
        <>
            <Container maxWidth="md" sx={{mt: 5}}>
                <Grid container spacing={1}>
                    <Grid item sm={2.5}>
                        <TextField fullWidth
                                   select
                                   value={currentType}
                                   onChange={handleTypeOnChange}
                        >
                            {Object.keys(SEARCH_TYPES).map(key => {
                                return <MenuItem key={SEARCH_TYPES[key]} value={SEARCH_TYPES[key]}>
                                    {SEARCH_TYPES[key]}
                                </MenuItem>
                            })}
                        </TextField>
                    </Grid>
                    <Grid item sm={9.5}>
                        <TextField
                            id="search"
                            label="Search"
                            fullWidth
                            autoComplete='off'
                            value={currentSearchValue}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                            onChange={(event) => setCurrentSearchValue(event.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {currentSearchValue &&
                                            <Tooltip title={"Clear"}>
                                                <IconButton color={'primary'}
                                                            onClick={handleClearButton}>
                                                    <CloseIcon/>
                                                </IconButton>
                                            </Tooltip>
                                        }
                                        <Tooltip title={"Search"}>
                                            <IconButton color={'primary'}
                                                        onClick={handleSearch}>
                                                <SearchIcon/>
                                            </IconButton>
                                        </Tooltip>

                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid>

                {itemsForDisplay.length !== 0 &&
                    <Box>
                        <Card>
                            <List sx={{width: '100%', bgcolor: 'background.paper'}}>
                                {itemsForDisplay.map(item => getItemButtonComponent(item))}
                            </List>
                        </Card>
                    </Box>}
                <Box pt={2}>
                    <Card>
                        <Grid container>
                            <Grid item sm={6}>
                                <Typography m={2} variant="h5" gutterBottom>
                                    Edit history
                                </Typography>
                            </Grid>
                            <Grid item sm={6} display={'flex'} justifyContent={'end'} pr={1} pt={1}>
                                <Button size={'small'} onClick={handleHistoryClear}>
                                    Clear history
                                </Button>
                            </Grid>
                        </Grid>

                        {previousEdits && <List sx={{width: '100%', bgcolor: 'background.paper'}}>
                            {previousEdits.map((edit, index) => {
                                return getItemButtonComponent(edit, index)
                            })}
                        </List>}
                    </Card>
                </Box>


            </Container>
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                open={isOpenSnackBar}
                autoHideDuration={5000}
                onClose={handleCloseSnackBar}
            >
                <Alert onClose={handleCloseSnackBar} severity="error" sx={{width: '100%'}}>
                    No Campaigns found!
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                open={isOpenSnackBarAbTests}
                autoHideDuration={5000}
                onClose={handleCloseSnackBarAbTests}
            >
                <Alert onClose={handleCloseSnackBarAbTests} severity="error" sx={{width: '100%'}}>
                    No AB tests found!
                </Alert>
            </Snackbar>
        </>
    );
}