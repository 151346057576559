import {
    Box,
    Button, Checkbox,
    Container,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

import {useGetAllParametersQuery, useSaveParameterMutation} from "../../../core/reducers/parameters";
import {useState} from "react";
import ModalComponent from "./ModalComponent";
import {containsRestrictedChars, isAdmin, isEmpty} from "../../Utils/CommonUtils";
import Loading from "../../Loading";
import {useSelector} from "react-redux";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {DataGrid} from "@mui/x-data-grid";
import {Alert} from "@mui/lab";

export default function AudienceParameter() {
    const userSession = useSelector((state) => state.data.UserSession);

    class Parameter {
        constructor() {
            this.parameterName = "";
            this.isMain = false;
            this.allowedRules = [];
            this.allowedValues = [];
        }
    }


    const [createParameter, setCreateParameter] = useState(false);
    const [updateParameter, setUpdateParameter] = useState(false);
    const [currentParameter, setCurrentParameter] = useState(new Parameter());
    const [errorMessage, setErrorMessage] = useState('');
    const {
        data: parameters,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error
    } = useGetAllParametersQuery()
    const [saveParameter, saveParameterResponse] = useSaveParameterMutation();


    const audience_options = [
        {title:'INCLUDE, ALL, ANY', value:['INCLUDE_ALL','INCLUDE_ANY']},
        {title:'INCLUDE or EXCLUDE', value:['INCLUDE_ALL','EXCLUDE_ALL','INCLUDE_ANY','EXCLUDE_ANY']},
        {title:'NUMBER_RANGE', value:['NUMBER_RANGE']},
        {title:'VERSION_RANGE', value:['VERSION_RANGE']}
    ]
    const audience_options_values = [
        'INCLUDE_ALL','INCLUDE_ANY','EXCLUDE_ALL','EXCLUDE_ANY', 'NUMBER_RANGE', 'VERSION_RANGE'
    ]

    const columns = [
        { field: 'parameterName', headerName: 'Name', minWidth: 150, flex:1 },

        {
            field: 'allowedRules',
            headerName: 'Type',
            width: 190,
            renderCell: (parameter) => {
                return parameter.value.length === 0 ? '[]' :
                    parameter.value.join(", ")
            },
        },
        {
            field: 'isMain',
            headerName: 'Is Main',
            width: 140,
            renderCell: (params) => (
                <Checkbox checked={params.value} disabled />
            ),
        },
        {
            field: 'allowedValues',
            headerName: 'Allowed Values',
            minWidth: 240,
            flex:1,
            renderCell: (parameter) => {
                return parameter.value.length === 0 ? '[]' :
                    parameter.value.join(", ")
            },
        },

    ];
    if(isAdmin(userSession)) {
        columns.push({
            field: 'edit',
            headerName: '',
            width: 70,
            renderCell: (params) => {
                // Assuming isAdmin is available in the component's scope
                return isAdmin(userSession) ? (
                    <IconButton
                        aria-label="edit"
                        onClick={() => handleEditParameter(params.row.parameterName)}
                    >
                        <EditIcon />
                    </IconButton>
                ) : null;
            },
            sortable: false,
            filterable: false,
        })

    }
    const rows = parameters==undefined ? null : parameters.map(item => ({
        id: item.parameterName,
        parameterName: item.parameterName,
        isMain: item.isMain,
        allowedRules: item.allowedRules,
        allowedValues: item.allowedValues,
    }));

    const handleCreateParameter = () => {
        setCurrentParameter(new Parameter())
        setCreateParameter(true);
    }

    const handleEditParameter = (index) => {

        setCurrentParameter(parameters.filter(item => item.parameterName == index)[0])
        setUpdateParameter(true)
    }

    const handleDialogClose = () => {
        setCreateParameter(false);
        setUpdateParameter(false);
        setErrorMessage('');
        setCurrentParameter(new Parameter())
    }

    const tableHeader = () => {
        return [
            '№', 'Parameter name', 'Is main', 'Allowed rules', 'Allowed values'
        ]
            .map(i => (
                <TableCell align="center">
                    <Typography align="center">{i}</Typography>
                </TableCell>
            ))
    }

    const handleChangeParameter = (e) => {
        let newParameter = {...currentParameter}
        newParameter[e.target.name] = e.target.value;
        setCurrentParameter(newParameter)
    }
    const handleChangeIsMain = (e) => {
        setCurrentParameter({
            ...currentParameter,
            isMain: !currentParameter.isMain
        })
    }

    const handleChangeAllowedRules = (value) => {
        let newParameter = {...currentParameter}

        newParameter.allowedRules = value //audience_options.filter(item => item.title == value)[0].value;
        setCurrentParameter(newParameter)
    }

    const handleChangeAllowedValues = (e) => {
        let newParameter = {...currentParameter}
        newParameter[e.target.name] = e.target.value.split(',').map(i => i.trim());
        setCurrentParameter(newParameter)
    }

    const handleSave = async () => {

        const snake_case_regexp = /^[a-z]+(_[a-z\d]+)*$/

        if (isEmpty(currentParameter.parameterName)) {
            setErrorMessage('Audience parameter name should not be empty')
        } else if (containsRestrictedChars(currentParameter.parameterName, [' '])) {
            setErrorMessage('Audience parameter name should not contain spaces')
        } else if (createParameter && parameters.filter(item => item.parameterName == currentParameter.parameterName).length > 0) {
            setErrorMessage('This name is used')
        } else if (!snake_case_regexp.test(currentParameter.parameterName)) {
            setErrorMessage('Name should be in snake case')
        } else {
            setErrorMessage('')
            let response = await saveParameter(currentParameter)
            //console.log(response)
            //setErrorMessage(response)

            handleDialogClose()
        }
    }

    return (
        <>
            <Container>

                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <h1>Audience Requirements</h1>

                    {isAdmin(userSession) && <Button variant="contained"
                                                     onClick={() => handleCreateParameter()}
                    >
                        Create new parameter
                    </Button>}
                </Box>

                {saveParameterResponse.isError && <Alert sx={{mt:2}} severity={"error"}>
                    {JSON.stringify(saveParameterResponse.error)}
                </Alert>}

                <Box pt={0} style={{ flexGrow: 1 }}>
                    <Loading isLoading={isLoading} isError={isError} error={error}>
                        <DataGrid
                            style={{ height: '75vh' }}
                            rows={rows}
                            columns={columns}
                            pageSize={100}

                        />


                    </Loading>
                </Box>
                <ModalComponent
                    title={updateParameter ? 'Update audience parameter' : 'Create audience parameter'}
                    open={updateParameter || createParameter}
                    handleDialogClose={() => handleDialogClose()}
                    currentParameter={currentParameter}
                    errorMessage={errorMessage}
                    handleChangeParameter={handleChangeParameter}
                    handleChangeIsMain={handleChangeIsMain}
                    handleChangeAllowedRules={handleChangeAllowedRules}
                    handleChangeAllowedValues={handleChangeAllowedValues}
                    handleSave={handleSave}
                    update={updateParameter}
                    saveParameterResponse={saveParameterResponse}
                    audience_options={audience_options_values}
                />
            </Container>
        </>
    )

}