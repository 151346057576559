import React, {useContext, useState, useEffect} from 'react';
import AbtestItem from "./AbtestItem";
import {CampaignItemContext} from "../../../core/context/context";


export default function AbtestsGroup({showAllGroups, rightPanel, showRainbow}) {

    const {types, campaignType, type, items, bundles, onSelectItem, selectedList, env} = useContext(CampaignItemContext)

    const [show, setShow] = useState(window.localStorage.getItem("show_group_" + type) == null ? true : window.localStorage.getItem("show_group_" + type) == 'true')

    const handleSetShow = () => {
        setShow(!show)
        window.localStorage.setItem("show_group_" + type, !show)
    }

    useEffect(() => {
        if(showAllGroups === true) {
            setShow(false)
            window.localStorage.setItem("show_group_" + type, false)
        }
        if(showAllGroups === false) {
            setShow(true)
            window.localStorage.setItem("show_group_" + type, true)
        }
    },[showAllGroups])

    return (
        <>
            <div key={type} className="campaign-group">
                <div className="campaign-type-title" onClick={() => handleSetShow()}>
                    {type}
                    <span className="campaign-type-title__amount">
                        {items.length}
                    </span>
                </div>

                {show && items.length > 0 && <div className="campaigns-list">
                    {items.map((item) => (
                        <AbtestItem
                            key={env+'_'+item.id}
                            showRainbow={showRainbow}
                            item={item}
                        />
                    ))}
                </div>}
            </div>
        </>
    )

}