import {hasContent} from "./CommonUtils";

const semver = require('semver');

export function validateVersion(from, to) {
    if (!from) {
        return semver.valid(to)
    } else if (!to) {
        return semver.valid(from)
    }
    try {
        return semver.lte(from, to);
    } catch (error) {
        return false;
    }
}

export function semverBothParams(from, to) {
    if (from && to) {
        try {
            return semver.lte(from, to);
        } catch (error) {
            return false
        }
    }
    return true;
}

export function semverValid(ver) {
    if (ver) {
        return semver.valid(ver);
    }
    return true;
}

export function validateNumber(from, to) {
    if (hasContent(from) && (from === '-0' || isNaN(parseInt(from)) || parseInt(from) < 0)) {
        return false;
    }
    if (hasContent(to) && (to === '-0' || isNaN(parseInt(to)) || parseInt(to) < 0)) {
        return false;
    }
    if (from && to) {
        return parseInt(from) <= parseInt(to);
    }
    return true;
}

export function validateSingleNumber(num) {
    debugger
    if (num) {
        return num >= 0;
    }
    return true;
}

export function validateLte(from, to) {
    if (from && to) {
        return parseInt(from) <= parseInt(to);
    }
    return true;
}