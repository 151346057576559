import {setError, setUser} from "../configureStore";
import {InteractionRequiredAuthError} from "@azure/msal-browser";

export const acquireToken = async (dispatch, msalInstance) => {
    const request = {
        forceRefresh: true,
        refreshTokenExpirationOffsetSeconds: 60000,
        scopes: [
            'user.read'
        ],
        account: msalInstance.getAllAccounts()[0],
        redirectUri: document.location.origin + "/dummy.html",
    };
    const request_redirect = {
        forceRefresh: true,
        refreshTokenExpirationOffsetSeconds: 60000,
        scopes: [
            'user.read'
        ],
        account: msalInstance.getAllAccounts()[0],
        redirectUri: document.location.origin + "/",
    };
    try {
        const response = await msalInstance.acquireTokenSilent(request);
        localStorage.setItem('azureToken', response.idToken)

    } catch (error) {
        console.error('get token error')
        console.error(JSON.stringify(error))
        console.error(typeof error)

        if(error.errorCode == 'no_network_connectivity') {
            console.error('no network')

        } else if (error instanceof InteractionRequiredAuthError) {
            try {
                const interactiveResponse = await msalInstance.acquireTokenPopup(request);
            } catch (e) {
                console.error(JSON.stringify(e))
                const interactiveResponse = await msalInstance.acquireTokenRedirect(request_redirect);

                // if(e.errorCode!='popup_window_error')
                //     document.location.reload()
            }
        } else {

        }
    }
};
