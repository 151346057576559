import api from "../api/base-api"

const campaignTypeApi = api.injectEndpoints({
        endpoints: (build) => ({
            getAllParameters: build.query({
                query: () => '/admin/parameter/getAll',
                providesTags: ['parameters'],
                keepUnusedDataFor: 0,
                transformResponse: (response) => {
                    return response.sort((a, b) => a.parameterName.localeCompare(b.parameterName)).sort((a, b) => (b.isMain - a.isMain));
                }
            }),
            getCampaignParameters: build.query({
                query: (data) => {
                    return {
                        url: `/admin/campaign/parameters?environment=${data.env}`,
                        env: data.env
                    }
                },
                providesTags: ['parameters'],
                keepUnusedDataFor: 0,
                transformResponse: (response) => {
                    return response.sort((a, b) => a.parameterName.localeCompare(b.parameterName)).sort((a, b) => (b.isMain - a.isMain));
                }
            }),
            saveParameter: build.mutation({
                query: (data) => {
                    let allowedSources = data.allowedRules ? data.allowedRules.map(i => `&allowedRules=${i}`).join('') : ""
                    let allowedValues = data.allowedValues ? data.allowedValues.map(i => `&allowedValues=${i}`).join('') : ""
                    return {
                        url: `/admin/parameter/add?parameterName=${data.parameterName}&isMain=${data.isMain}${allowedSources}${allowedValues}`,
                        body: data,
                        method: "POST",
                    }
                },
                invalidatesTags: ['parameters']
            })
        }),
    }
)

export const {
    useGetCampaignParametersQuery,
    useGetAllParametersQuery,
    useSaveParameterMutation,
} = campaignTypeApi;