const types = {
    DATE_FILTER_FROM: 'DATE_FILTER_FROM',
    DATE_FILTER_TILL: 'DATE_FILTER_TILL',
};

const initialDates = {
    from: new Date(new Date().getTime() - (24 * 60 * 60 * 1000) * 7).toDateString(),
    till: new Date(new Date().getTime() + (24 * 60 * 60 * 1000) * 365).toDateString()
}
export default (state = initialDates, action) => {
    switch (action.type) {
        case types.DATE_FILTER_FROM:
            return {
                ...state,
                from: action.payload && action.payload.data ? action.payload.data.from.toDateString() : "",
            }
        case types.DATE_FILTER_TILL:
            debugger
            return {
                ...state,
                till: action.payload && action.payload.data ? action.payload.data.till.toDateString() : "",
            }
        default:
            return state;
    }
}