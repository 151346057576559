import {CAMPAIGNS, getModeFromUrl, getEnvFromUrl} from "../../components/Utils/CommonUtils";

const types = {
    EV1_NAME: 'EV1_NAME',
    EV2_NAME: 'EV2_NAME',
    IS_PROD: 'IS_PROD',
    MODE: 'MODE',
    SWAP_ENV: 'SWAP_ENV'
};

const initialState = {
    env1Name: getEnvFromUrl('env'),
    env2Name: getEnvFromUrl('env2'),
    isProd: false,
    mode: getModeFromUrl()
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.EV1_NAME:
            return {
                ...state,
                env1Name: action.payload && action.payload.data ? action.payload.data.env1Name : "",
            };

        case types.EV2_NAME:
            return {
                ...state,
                env2Name: action.payload && action.payload.data ? action.payload.data.env2Name : "",
            };
        case types.SWAP_ENV:
            return {
                ...state,
                env2Name: action.payload.data.env2,
                env1Name: action.payload.data.env1
            };
        case types.IS_PROD:
            return {
                ...state,
                isProd: action.payload && action.payload.data ? action.payload.data.isProd : ""
            };
        case types.MODE:
            return {
                ...state,
                mode: action.payload && action.payload.data ? action.payload.data.mode : CAMPAIGNS
            };

        default:
            return state;
    }
};
