import {Autocomplete, Button, Chip, DialogActions, DialogContent, FormHelperText, Grid, TextField} from "@mui/material";
import CommonModal from "../CommonModal";
import {useGetAllUserGroupUsersQuery} from "../../../core/reducers/userGroup";
import {useState} from "react";
import {containsRestrictedChars, isEmpty} from "../../Utils/CommonUtils";

export default function ModalComponent(props) {

    const [inputValue, setInputValue] = useState("");

    if (props.isSuccess && !props.currentUserGroup.updated) {
        let newUserGroup = {...props.currentUserGroup}
        newUserGroup.userIds = props.existingUsersList
        newUserGroup.updated = true
        props.setCurrentUserGroup(newUserGroup);
    }

    // props.setCurrentUserGroup(existingUsersList);

    const handleSave = (existingUsersList = [], updateMode) => {
        const snake_case_regexp = /^[a-z]+(_[a-z\d]+)*$/

        if (isEmpty(props.currentUserGroup.groupId)) {
            props.setErrorMessage('User group name should not be empty');
            return;
        }
        if (containsRestrictedChars(props.currentUserGroup.groupId, [' '])){
            props.setErrorMessage('User group name should not contain spaces');
            return;
        }
        if (!snake_case_regexp.test(props.currentUserGroup.groupId)) {
            props.setErrorMessage('User group name should be in snake case');
            return;
        }
        if(!props.update) {
            if(props.userGroups.groups.filter(item => item.groupId == props.currentUserGroup.groupId).length > 0) {
                props.setErrorMessage('This user group name is used');
                return;
            }
        }
        if (updateMode) {
            const usersToRemove = existingUsersList.filter(n => !props.currentUserGroup.userIds.includes(n));
            if (usersToRemove.length > 0) {
                props.deleteUsers({
                    groupId: props.currentUserGroup.groupId,
                    body: {
                        users: usersToRemove
                    }
                })
            }
        }

        const usersToSave = props.currentUserGroup.userIds.filter(n => !existingUsersList.includes(n));

        if (usersToSave.length > 0) {
            props.saveUserGroup(
                {
                    groupId: props.currentUserGroup.groupId,
                    body: {
                        users: usersToSave
                    }
                }
            )
        }
        props.handleDialogClose()
    }

    let getContent = () => {
        return (
            <>
                <DialogContent>
                    {<Grid container spacing={2} pt={1}>
                        <Grid item xs={12}>
                            <TextField fullWidth
                                       disabled={props.update}
                                       name="groupId"
                                       label="Name"
                                       value={props.currentUserGroup.groupId ? props.currentUserGroup.groupId : ""}
                                       error={!isEmpty(props.errorMessage)}
                                       onChange={props.handleChangeUserGroup}>
                                {props.id}
                            </TextField>
                            <FormHelperText style={{color: "red"}}>
                                {props.errorMessage}
                            </FormHelperText>
                        </Grid>
                        {<Grid item xs={12}>
                            <Autocomplete
                                clearIcon={false}
                                options={[]}
                                freeSolo
                                value={props.currentUserGroup.userIds}
                                inputValue={inputValue}
                                onChange={(e, v) => props.handleChangeUserIds(v)}
                                multiple
                                renderTags={(value, props) =>
                                    value.map((option, index) => (
                                        <Chip label={option} {...props({index})} />
                                    ))
                                }
                                onInputChange={(event, newInputValue) => {
                                    const options = newInputValue.split(',');
                                    debugger
                                    const optionsWithSpace = newInputValue.split(' ');

                                    if (options.length > 1) {
                                        props.handleChangeUserIds(
                                            props.currentUserGroup.userIds
                                                .concat(options)
                                                .map(x => x.trim())
                                                .map(x=>x.replaceAll(/'|"/g, ""))
                                                .filter(x => x)
                                        );
                                        setInputValue('');
                                    } else if (optionsWithSpace.length > 1) {
                                        props.handleChangeUserIds(
                                            props.currentUserGroup.userIds
                                                .concat(optionsWithSpace)
                                                .map(x => x.trim())
                                                .map(x => x.replaceAll(/'|"/g, ""))
                                                .filter(x => x)
                                        );
                                        setInputValue('');
                                    } else {
                                        setInputValue(newInputValue);
                                    }
                                }}
                                onBlur={(newInputValue)=> {
                                    props.handleChangeUserIds(
                                        props.currentUserGroup.userIds
                                            .concat(newInputValue.target.value)
                                            .map(x => x.trim())
                                            .map(x=>x.replaceAll(/'|"/g, ""))
                                            .filter(x => x)
                                    );
                                    setInputValue('');
                                }}
                                renderInput={(params) =>
                                    <TextField
                                        label="Users (separated by comma)"
                                        {...params}
                                    />}
                            />
                        </Grid>}
                    </Grid>}
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} onClick={() => handleSave(props.existingUsersList, props.update)}>
                        Save
                    </Button>
                </DialogActions>
            </>
        );
    }

    return (
        <>
            <CommonModal
                open={props.open}
                handleDialogClose={props.handleDialogClose}
                title={props.title}
                content={getContent()}
            />
        </>
    )
}