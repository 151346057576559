import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import JsonViewer from "../Utils/JsonViewer";
import {Fragment, useEffect, useState} from "react";
import {getEnvName} from "../Utils/CommonUtils";
import {useNavigate} from "react-router";
import {useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";

export default function ResultDialog({saveAbTestResponse}) {

    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const navigate = useNavigate();
    const sourceMode = useSelector((state) => state.data.SourceMode);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (saveAbTestResponse && saveAbTestResponse.status === 'fulfilled') {
            setSuccessDialogOpen(true);
        } else if (saveAbTestResponse && saveAbTestResponse.isError) {
            setErrorDialogOpen(true)
        }
    }, [saveAbTestResponse])

    const handleCreateAnotherCampaign = () => {
        let curr = window.location.pathname;
        if ('/abtests' === curr) {
            window.location.reload();
        } else {
            navigate(`/creator/abtests?env=${getEnvName('env', sourceMode, searchParams)}`)
        }
    }

    const handleGoToPublisher = () => {
        let curr = window.location.pathname;
        if ('/publisher' === curr) {
            window.location.reload();
        } else {
            navigate(`/publisher/abtests?env=${sourceMode.env1Name}&env2=${sourceMode.env2Name}`)
        }
    }

    const handleDialogClose = () => {
        setSuccessDialogOpen(false);
        setErrorDialogOpen(false);
    }

    return <Fragment>
        <Dialog
            open={successDialogOpen}
            onClose={() => handleDialogClose()}
            scroll={"paper"}

        >
            <DialogTitle>
                {"AB test saved"}
            </DialogTitle>
            <DialogContent dividers={true}>
                <DialogContentText id="alert-dialog-description">
                    <JsonViewer json={saveAbTestResponse?.data}></JsonViewer>
                </DialogContentText>

            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={() => handleCreateAnotherCampaign()}>
                    Create another AB test
                </Button>
                <Button
                    variant="contained"
                    onClick={() => handleGoToPublisher()}>

                    Go to publisher
                </Button>
            </DialogActions>
        </Dialog>
        <Dialog open={errorDialogOpen} onClose={() => handleDialogClose()}>
            <Box minWidth={300} minHeight={300}>
                <DialogTitle>
                    {"Error"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {saveAbTestResponse?.error?.data?.message}
                    </DialogContentText>
                    <DialogActions>
                        <Button
                            onClick={() => handleDialogClose()}>
                            Close
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Box>
        </Dialog>
    </Fragment>
}