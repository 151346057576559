import {Autocomplete, Button, Chip, DialogActions, DialogContent, FormHelperText, Grid, TextField} from "@mui/material";
import CommonModal from "../CommonModal";
import {useGetAllGroupsForUserQuery, useGetAllUserGroupUsersQuery} from "../../../core/reducers/userGroup";
import {useState} from "react";
import {containsRestrictedChars, isEmpty} from "../../Utils/CommonUtils";
import Loading from "../../Loading";
import {Alert} from "@mui/lab";

export default function UserGroupAmount(props) {

    console.log(props.existingUsersList)
    return (
        <>
            <CommonModal
                open={true}
                title={"Users in group " + props.group.groupId}
                handleDialogClose={props.handleDialogClose}

            >
                <DialogContent>
                    {props.existingUsersList!=undefined && props.existingUsersList.map((item) => <div>{item}</div>)}
                </DialogContent>

            </CommonModal>
        </>
    )
}