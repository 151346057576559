import CampaignForm, {Campaign, CampaignTemplate} from "./Campaign/CampaignForm";
import {useLocation, useParams} from "react-router-dom";
import UpdateCampaignForm from "./Campaign/UpdateCampaignForm";
import {getEnvName, getFullEnvironment} from "./Utils/CommonUtils";
import {Box, Button, Card, CardContent, Paper, Typography} from "@mui/material";
import {MODE} from "./Campaign/campaignMode";
import {useSelector} from "react-redux";
import AbTestForm, {AbTest} from "./AbTest/AbTestForm";
import UpdateAbTestForm from "./AbTest/UpdateAbTestForm";
import TemplateFromCampaignForm from "./Campaign/TemplateFromCampaignForm";
import {useState} from "react";
import {CloneFromCampaign} from "./CloneFromCampaign";
import AbTestMiniForm from "./AbTest/AbTestMiniForm";

export const FORM_TYPES = {
    AB_TESTS: 'abTestType',
    CAMPAIGNS: 'campaignType',
    TEMPLATES: 'templatesType',
    AB_TEST_MINI: 'abTestMiniType',
}

export default function FormWrapper({formType}) {

    const params = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const sourceMode = useSelector((state) => state.data.SourceMode);
    let env1 = getEnvName('env', sourceMode, searchParams)
    const locationParams = Object.fromEntries(searchParams.entries());

    let hasCreator = false;
    if (getFullEnvironment(env1)) {
        let e = getFullEnvironment(env1);
        hasCreator = e.hasCreator
    }

    const [formMode, setFormMode] = useState('')

    if(locationParams['mode']=='variants' && formMode == '' && formType===FORM_TYPES.CAMPAIGNS) {
        return <Box display={'flex'} justifyContent={'center'}>
            <Box component="main" maxWidth="sm" sx={{mb: 4, mt:3}} display={'grid'}>

                    <Card>
                        <CardContent>
                            <div>
                                <Button variant={"outlined"} fullWidth={true} sx={{mb:1}} onClick={() => setFormMode('FULL')}>Create from scratch</Button>
                            </div>
                            <div>
                                <Button variant={"outlined"} onClick={() => setFormMode('CLONE')}>Copy settings from existing campaign</Button>
                            </div>
                        </CardContent>
                    </Card>
            </Box>
        </Box>
    }

    if(locationParams['mode']=='variants' && formMode == 'CLONE' && formType===FORM_TYPES.CAMPAIGNS) {
        return <CloneFromCampaign />
    }

    if (!hasCreator) {
        return <Box display={'flex'} justifyContent={'center'}>
            <Box component="main" maxWidth="md" sx={{mb: 4}} display={'grid'}>
                <Paper
                    variant="outlined" sx={{my: {xs: 3, md: 2}, p: {xs: 2, md: 3}}}
                >
                    <Typography component="h1" variant="h4" align="center">
                        В данном окружении нельзя создавать кампании
                    </Typography>
                </Paper>
            </Box>
        </Box>
    }

    if (formType===FORM_TYPES.CAMPAIGNS) {
        if (params.id) {
            if (locationParams.hasOwnProperty('clone')) {
                return <UpdateCampaignForm campaignId={params.id} mode={MODE.CLONE}/>
            }
            return <UpdateCampaignForm campaignId={params.id} mode={MODE.UPDATE}/>
        } else {
            let newCampaign = new Campaign();
            if(formMode=='FULL') {
                newCampaign.bundleId = locationParams['bundleId']
                newCampaign.offerType = locationParams['type']
            }
            return <CampaignForm campaign={newCampaign} mode={MODE.CREATE}/>
        }
    } else if (formType===FORM_TYPES.AB_TESTS) {
        if (params.id) {
            if (locationParams.hasOwnProperty('clone')) {
                return <UpdateAbTestForm abTestId={params.id} mode={MODE.CLONE}/>
            }
            return <UpdateAbTestForm abTestId={params.id} mode={MODE.UPDATE}/>
        }
        return <AbTestForm abTest={new AbTest()} mode={MODE.CREATE}/>
    } else if (formType===FORM_TYPES.AB_TEST_MINI) {
        return <AbTestMiniForm abTest={new AbTest()} mode={MODE.CREATE}/>
    } else if(formType===FORM_TYPES.TEMPLATES) {
        if (params.id) {
            let mode = locationParams.hasOwnProperty('campaign') ? MODE.POLY : (locationParams.hasOwnProperty('clone') ? MODE.CLONE : MODE.UPDATE)
            return <UpdateCampaignForm template={true} campaignId={params.id} mode={mode}/>
        }

        return <CampaignForm template={true} campaign={new CampaignTemplate()} mode={MODE.CREATE}/>
    }
}