import React from "react";
import {Chip} from "@mui/material";

export default function AudienceOffer({audience}) {

    function convertSecondsToISO8601Duration(seconds) {
        const days = Math.floor(seconds / 86400);
        const hours = Math.floor((seconds % 86400) / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;

        let duration = 'P';
        if (days > 0) duration += `${days}D`;
        if (hours > 0 || minutes > 0 || secs > 0) {
            duration += 'T';
            if (hours > 0) duration += `${hours}H`;
            if (minutes > 0) duration += `${minutes}M`;
            if (secs > 0) duration += `${secs}S`;
        }

        return duration;
    }

    const getLabel = () => (
        <>
            <em>{audience.parameter} </em>

            ({audience.hasOwnProperty('offerId') && <>{audience.offerId}</>}
            {audience.hasOwnProperty('offerIds') && <>{audience.offerIds.join(', ')}</>}
            {audience.hasOwnProperty('types') && <>{audience.types.join(', ')}</>}
            {audience.hasOwnProperty('offset') && <>:&nbsp;{convertSecondsToISO8601Duration(audience.offset )}&nbsp;</>})
        </>
    )

    return (
        <Chip label={getLabel()}/>
    )

}