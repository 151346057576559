import {useSearchParams} from "react-router-dom";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getModeFromUrl, getEnvName, PROD} from "../components/Utils/CommonUtils";

export default function EnvironmentProvider(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const sourceMode = useSelector((state) => state.data.SourceMode);
    const dispatch = useDispatch();

    let env1 = getEnvName('env', sourceMode, searchParams)
    let env2 = getEnvName('env2', sourceMode, searchParams)
    let appMode = getModeFromUrl()

    useEffect(() => {
        dispatch({
            type: 'IS_PROD', payload: {
                data: {
                    isProd: env2 === PROD
                }
            }
        });
        const q = searchParams.get('q') ? searchParams.get('q') : null;
        if (q) {
            //setSearchParams({env: env1, env2: env2, q: q})
        } else {
            //setSearchParams({env: env1, env2: env2, q: q})
        }
    }, []);


    useEffect(() => {
        dispatch({
            type: 'MODE', payload: {
                data: {
                    mode: appMode
                }
            }
        });

    }, [appMode])

    return (
        <>
            {props.children}
        </>
    )
}