import {Fragment, useState} from "react";
import {Button, Chip, Grid} from "@mui/material";
import CohortContent from "./CohortContent";
import {useGetAllUserGroupsQuery} from "../../../core/reducers/userGroup";

export default function CohortStep({
                                       currentAbTest,
                                       handleDeleteCohort,
                                       handleAddNewCohort,
                                   }) {

    const [currentCohortIndex, setCurrentCohortIndex] = useState(0);
    const [cohortUserGroups, setCohortUserGroups] = useState({});
    const {
        data: userGroups,
    } = useGetAllUserGroupsQuery({env:"dev"});

    const handleDecrementCurrentCohortIndex = (index) => {
        if (index > currentCohortIndex) {
            return
        }
        if (currentCohortIndex > 1) {
            setCurrentCohortIndex(currentCohortIndex - 1);
        } else {
            setCurrentCohortIndex(0)
        }
    }

    const createCohortContentComponent = () => {
        return <CohortContent
            userGroups={userGroups}
            key={currentCohortIndex}
            currentAbTest={currentAbTest}
            currentCohortIndex={currentCohortIndex}
            cohortUserGroups={cohortUserGroups}
            setCohortUserGroups={setCohortUserGroups}
        />
    }

    return (
            <Grid container spacing={3} minWidth={'900px'}>
                <Grid container xs={12} pl={3} spacing={1}>
                    {currentAbTest.cohorts.map((cohort, index) => (
                        <Grid item key={index} xs={2}>
                            <Chip
                                style={{
                                    borderRadius: "5px",
                                    height: '40px',
                                    width: '100%'
                                }}
                                variant={index === currentCohortIndex ? 'contained' : 'outlined'}
                                label={cohort.id}
                                color={'warning'}
                                onClick={() => setCurrentCohortIndex(index)}
                                onDelete={
                                    currentAbTest.cohorts.length > 1 ? () => {
                                        handleDecrementCurrentCohortIndex(index);
                                        handleDeleteCohort(index)
                                    } : ""
                                }
                            />
                        </Grid>
                    ))}
                    <Grid item>
                        <Button
                            variant={'outlined'}
                            color={'warning'}
                            size={'small'}
                            onClick={() => {
                                handleAddNewCohort(currentAbTest.cohorts.length)
                                setCurrentCohortIndex(currentAbTest.cohorts.length)
                            }
                            }
                            fullWidth
                            style={{
                                height: '40px'
                            }}
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>
                {createCohortContentComponent()}
            </Grid>
    )
}