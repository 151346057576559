import {
    Autocomplete,
    Button, Checkbox,
    DialogActions,
    DialogContent, FormControlLabel,
    FormHelperText,
    Grid,
    MenuItem,
    TextField,
} from "@mui/material";
import CommonModal from "../CommonModal";
import {isEmpty} from "../../Utils/CommonUtils";
import {LoadingButton} from "@mui/lab";

export default function ModalComponent(props) {

    let getContent = () => {
        return (
            <>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField fullWidth
                                       disabled={props.update}
                                       name="parameterName"
                                       label="Name"
                                       value={props.currentParameter.parameterName}
                                       error={!isEmpty(props.errorMessage)}
                                       onChange={props.handleChangeParameter}>
                                {props.currentParameter.parameterName}
                            </TextField>
                            <FormHelperText style={{color: "red"}}>
                                {props.errorMessage}
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={props.currentParameter.isMain}
                                        onChange={props.handleChangeIsMain}
                                        name="isMain"
                                    />
                                }
                                label="Is main"
                            />


                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete

                                multiple
                                value={props.currentParameter.allowedRules}
                                options={props.audience_options}
                                onChange={(e, v) =>
                                    props.handleChangeAllowedRules(v)}
                                renderInput={params => (
                                    <TextField {...params}
                                               fullWidth
                                               label="Type"
                                    />
                                )}>

                            </Autocomplete>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="allowedValues"
                                label="Allowed values"
                                value={props.currentParameter.allowedValues ? props.currentParameter.allowedValues.join(',') : "www"}
                                onChange={props.handleChangeAllowedValues}
                                fullWidth
                                helperText='Comma separated array'/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={props.saveParameterResponse.isLoading} variant={'contained'} onClick={props.handleSave}>
                        Save
                    </LoadingButton>
                </DialogActions>
            </>
        )
    }

    return (
        <>
            <CommonModal
                open={props.open}
                handleDialogClose={props.handleDialogClose}
                title={props.title}
                content={getContent()}
            />
        </>
    )

}