import {Autocomplete, Box, Button, Card, CardContent, TextField} from "@mui/material";
import {useGetAllCampaignsQuery} from "../core/reducers/campaign";
import {getCurrentParam} from "./Campaign/CampaignForm";
import {useState} from "react";
import {useNavigate} from "react-router";
import Loading from "./Loading";

export const CloneFromCampaign = () => {

    const [selectedCampaign, setSelectedCampaign] = useState(null)
    const navigate = useNavigate();

    const query = {}
    query.env = 'liveops'
    query.from = Math.round(new Date(new Date().getTime() - (24 * 60 * 60 * 1000) * 7).setHours(0, 0, 0, 0))/1000
    query.till = Math.round(new Date(new Date().getTime() + (24 * 60 * 60 * 1000) * 365).setHours(0, 0, 0, 0))/1000

    const {
        data: campaigns,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error,
        refetch
    } = useGetAllCampaignsQuery(query, {
    });


    return <Box display={'flex'} justifyContent={'center'}>
        <Box component="main" maxWidth="sm" sx={{mb: 4, mt:3}} display={'grid'}>
            <Card style={{width:"400px"}}>
                <CardContent>
                    <div>
                        <Loading isLoading={isLoading} isError={isError} error={error}>
                            {campaigns!=undefined && <Autocomplete
                                sx={{mb:2}}
                                fullWidth={true}
                                loading={isLoading}
                                options={campaigns.campaigns}
                                onChange={(e, v) =>
                                    setSelectedCampaign(v.id)}
                                getOptionLabel={(option) => option.id}
                                renderInput={(params) => <TextField {...params} label="Search" variant="outlined" />}
                                filterOptions={(options, state) =>
                                    options.filter((option) => option.id.toLowerCase().includes(state.inputValue.toLowerCase()))
                                }
                            />}
                        </Loading>
                    </div>
                    <div>
                        <Button disabled={selectedCampaign==null} fullWidth={true} variant={"outlined"} onClick={() => {
                            navigate(`/creator/campaigns/${selectedCampaign}?env=liveops&clone`)
                        }} >Create</Button>
                    </div>
                </CardContent>
            </Card>
        </Box>
    </Box>
}