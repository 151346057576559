import React, {useContext, useState, useEffect} from 'react';
import CampaignItem from "./CampaignItem";
import {CampaignItemContext} from "../../../core/context/context";


export default function CampaignGroup({showAllGroups, rightPanel, showRainbow}) {

    const {types, campaignType, type, items, bundles, onSelectItem, selectedList, env} = useContext(CampaignItemContext)

    const [show, setShow] = useState(window.localStorage.getItem("show_group_" + type) == null ? true : window.localStorage.getItem("show_group_" + type) == 'true')

    const getBundleById = (bundle_id) => {
        let bundle = bundles.filter((item) => item.id == bundle_id)
        if (bundle.length > 0) return bundle[0]
        return false
    }

    const handleSetShow = () => {
        setShow(!show)
        window.localStorage.setItem("show_group_" + type, !show)
    }

    const getAbbreviationForType = (type, types) => {
        const currentType = types.filter(item => item.id === type);
        if (currentType.length === 0) return '';
        if (currentType[0].metaData === null || currentType[0].metaData?.abbreviation === null) return '';

        return currentType[0].metaData?.abbreviation.trim() === '' ? '' : ' (' + currentType[0].metaData?.abbreviation + ')';
    }

    useEffect(() => {
        if(showAllGroups === true) {
            setShow(false)
            window.localStorage.setItem("show_group_" + type, false)
        }
        if(showAllGroups === false) {
            setShow(true)
            window.localStorage.setItem("show_group_" + type, true)
        }
    },[showAllGroups])

    return (
        <>
            <div key={type} className="campaign-group">
                <div className="campaign-type-title" onClick={() => handleSetShow()}>
                    {type + getAbbreviationForType(type, types)}
                    <span className="campaign-type-title__amount">
                        {items.length}
                    </span>
                </div>

                {show && items.length > 0 && <div className="campaigns-list">
                    {items.map((item, index) => (
                        <CampaignItem
                            showRainbow={showRainbow}
                            key={env+'_'+index}
                            bundle={getBundleById(item.bundleId)}
                            campaignType={campaignType}
                            item={item}
                            rightPanel={rightPanel}
                        />
                    ))}
                </div>}
            </div>
        </>
    )

}