import {Button, DialogActions} from "@mui/material";
import CommonModal from "./CommonModal";

export default function CommonDeleteModal(props) {

    const removeEnvironment = () => {
        props.removeEnvironment(props.currentEnvName);
        props.close()
    }

    const getContent = () => {
        return (
            <>
                <DialogActions>
                    <Button variant={"outlined"} onClick={props.close}>
                        Cancel
                    </Button>
                    <Button variant={"contained"} onClick={removeEnvironment}>
                        Accept
                    </Button>
                </DialogActions>
            </>
        )
    }

    return (
        <CommonModal
            open={props.open}
            handleDialogClose={props.close}
            title={props.title}
            content={getContent()}
        />
    )
}