import React from "react";
import {Chip} from "@mui/material";

export default function AudienceInclude({audience, title}) {

    const getLabel = () => (
        <>
            <>{audience.parameter} <b>{title}</b>: </>
            {
                typeof audience.values == 'object' ? audience.values.join(', ') : audience.values.toString()
            }&nbsp;
        </>
    )

    return (
        <Chip label={getLabel()}/>
    )

}