import {
    Autocomplete, Button, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    Grid,
    IconButton,
    TextField
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {getEnvName, isEmpty} from "../Utils/CommonUtils";
import {Alert, LoadingButton} from "@mui/lab";
import {useContext, useState} from "react";
import {useGetAllBundlesQuery} from "../../core/reducers/campaign";
import {useCreateVariantBaseMutation} from "../../core/reducers/variant";
import {SnackBarContext} from "../../core/context/snackBarProvider";

export const NewVariantModal = ({openCreate, setOpenCreate}) => {
    const [createVariant, createVariantResponse] = useCreateVariantBaseMutation();

    console.log('createVariantResponse')
    console.log(createVariantResponse)
    const useSnackBars = () => useContext(SnackBarContext);
    const {addAlert} = useSnackBars();

    const typeOptions = ["universal_offer"]; // Пример констант для type
    const {
        data: bundles,
    } = useGetAllBundlesQuery('liveops');
    const bundleOptions = ["Bundle1", "Bundle2", "Bundle3"]; // Пример констант для bundle


    const [newVariant, setNewVariant] = useState({
        type: "",
        bundleId: "",
        balanceLink: "",
        docLink: ""

    });

    const handleChange = (field, value) => {
        let isValid = true;

        if (field === 'balanceLink') {
            isValid = validateBalanceLink(value);
        } else if (field === 'docLink') {
            isValid = validateWikiLink(value);
        } else {
            isValid = !isEmpty(value);
        }

        setNewVariant(prev => ({
            ...prev,
            [field]: value
        }));

        setErrors(prev => ({
            ...prev,
            [field]: !isValid
        }));
    }


    const isFormValid = () => {
        return !Object.values(errors).some(error => error) &&
            Object.values(newVariant).every(value => !isEmpty(value));
    }


    const [errors, setErrors] = useState({
        type: false,
        bundle: false,
        balanceLink: false,
        docLink: false
    });


    const validateBalanceLink = (link) => {
        const regex = /^https:\/\/docs\.google\.com\/spreadsheets\/.+/;
        return regex.test(link);
    };


    const validateWikiLink = (link) => {
        const regex = /^https:\/\/belka-games\.atlassian\.net\/wiki\/.+/;
        return regex.test(link);
    };

    const handleCreate = async () => {
        const response = await createVariant({
            body: {...newVariant},
            env: 'liveops'
        })


        if (response.hasOwnProperty('data') && response.data.hasOwnProperty('bundleId')) {
            addAlert({text: 'Variant created', type: 'success'})
            setOpenCreate(false)
        } else {
            addAlert({text: 'Error '+ response.error.status + ' ' + response.error.data.message, type: 'error'})
        }
    }

    return <Dialog open={openCreate} onClose={() => setOpenCreate(false)} >
        <DialogTitle>Create Base Variant</DialogTitle>
        <IconButton
            aria-label="close"
            onClick={() => setOpenCreate(false)}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
        >
            <CloseIcon/>
        </IconButton>
        <DialogContent>
            {createVariantResponse.status=='rejected'&&<Alert sx={{mb:2}} severity={"error"}>{createVariantResponse.error?.data?.message}</Alert>}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Autocomplete
                        options={typeOptions}
                        value={newVariant.type}
                        onChange={(e, v) => handleChange('type', v)}
                        renderInput={(params) => (
                            <TextField {...params}
                                       fullWidth
                                       label="Type"
                                       error={errors.type}
                                       helperText={errors.type ? "This field is required" : ""}
                            />
                        )}
                    />
                </Grid>

                {bundles && <Grid item xs={12}>
                    <Autocomplete
                        required
                        options={bundles.map(bundle => bundle.id).sort()}

                        onChange={(e, v) => handleChange('bundleId', v)}

                        value={newVariant.bundleId}
                        renderInput={params => (
                            <TextField
                                {...params}
                                id="bundleId"
                                name="bundleId"
                                label="Bundle ID *"
                                variant="outlined"
                                error={errors.bundleId}
                                helperText={errors.bundleId ? "This field is required" : ""}
                            />
                        )}
                    />
                </Grid>}

                <Grid item xs={12}>
                    <TextField
                        name="balanceLink"
                        label="Balance Link"
                        value={newVariant.balanceLink}
                        onChange={(e) => handleChange('balanceLink', e.target.value)}
                        fullWidth
                        error={errors.balanceLink}
                        helperText={errors.balanceLink ? "Please enter a valid Google Sheets link" : ""}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        name="docLink"
                        label="Document Link"
                        value={newVariant.docLink}
                        onChange={(e) => handleChange('docLink', e.target.value)}
                        fullWidth
                        error={errors.docLink}
                        helperText={errors.docLink ? "Please enter a valid https://belka-games.atlassian.net/wiki link" : ""}
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpenCreate(false)} variant={'outlined'}>
                Cancel
            </Button>
            <LoadingButton loading={createVariantResponse.isLoading} onClick={handleCreate} variant={'contained'} disabled={!isFormValid()}>
                Create
            </LoadingButton>
        </DialogActions>
    </Dialog>
}