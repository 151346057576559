import React from 'react';

import {useGetTransferStatusQuery} from "../../../core/reducers/campaign";
import {Box, CircularProgress, Tooltip, Typography} from "@mui/material";


export default function SyncPublisherProgress() {

    const {
        data: statuses,
    } = useGetTransferStatusQuery({env:'production'}, {
        pollingInterval: 5000,
    })


    const ready = (statuses != undefined && statuses.hasOwnProperty('statuses')) ? statuses.statuses.filter(item => true) : [];

    let c = 1;
    let text = []
    let loadPercent = 1;

    if(statuses != undefined && ready.length > 0) {
        loadPercent = ready.reduce((a, c) => a+c.loadPercent*100, ready.length)
        ready.map(a => {
            text.push(a.campaignId + ' ' + Math.round(a.loadPercent*100) + '%')
        })
        loadPercent = c/ready.length;
    }


    return <>
        {(statuses != undefined && ready.length > 0) &&
            <Box sx={{position: 'relative', display: 'inline-flex', float: 'right',left:"-10px"}}>

                <CircularProgress variant="determinate" value={loadPercent}/>

                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Tooltip title={text.join(', ')}>

                    <Typography variant="caption" component="div" color="text.secondary">
                        {`${Math.round(loadPercent)}%`}
                    </Typography>
                    </Tooltip>
                </Box>
            </Box>
        }
    </>

}