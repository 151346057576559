import {
    Autocomplete,
    Box,
    Checkbox,
    FormControlLabel,
    Grid, IconButton,
    ListSubheader,
    Paper,
    TextField,
    Tooltip
} from "@mui/material";
import {Fragment, useContext, useEffect, useId, useState} from "react";
import {CohortContext} from "../../../core/context/context";
import {isValidInteger, isEmpty, isValidJson} from "../../Utils/CommonUtils";
import {CohortError} from "../AbTestForm";
import {v4 as uuid} from "uuid";
import {getCurrentParam} from "../../Campaign/CampaignForm";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


export default function CohortContent({currentAbTest, currentCohortIndex, userGroups, cohortUserGroups, setCohortUserGroups}) {
    const context = useContext(CohortContext);
    const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
    useEffect(() => {
        return _validateId()
    }, [currentAbTest.cohorts])

    const _validateId = () => {
        const id = currentAbTest.cohorts[currentCohortIndex].id;
        _registerErrorIfNotConform(!isEmpty(id) && !/^Cohort \d+$/.test(id), 'id')
    }

    const _validateLimit = () => {
        const num = currentAbTest.cohorts[currentCohortIndex].limit;
        debugger
        _registerErrorIfNotConform((isValidInteger(num) && limitedActiveLimitPositive()), 'limit')
    }

    const limitedActiveLimitPositive = () => {
        if (currentAbTest.cohorts[currentCohortIndex].limited) {
            return currentAbTest.cohorts[currentCohortIndex].limit >= 0;
        } else {
            return true;
        }
    }
    const _validateWeight = () => {
        const num = currentAbTest.cohorts[currentCohortIndex].weight;
        debugger
        _registerErrorIfNotConform(isValidInteger(num) && num >= 0, 'weight')
    }

    const _validateLimited = () => {
        console.log('limitedActiveLimitPositive()', limitedActiveLimitPositive())
        console.log('currentAbTest.cohorts[currentCohortIndex].limited && currentAbTest.cohorts[currentCohortIndex].limit >= 0;', currentAbTest.cohorts[currentCohortIndex].limited && currentAbTest.cohorts[currentCohortIndex].limit >= 0)
        _registerErrorIfNotConform(limitedActiveLimitPositive(), 'limit')
    }

    const _validateParameters = () => {
        const parameters = currentAbTest.cohorts[currentCohortIndex].parameters;
        _registerErrorIfNotConform(isValidJson(parameters), 'parameters')
    }

    const _registerErrorIfNotConform = (condition, field) => {
        if (!condition) {
            context._handleCohortErrorRegistration(new CohortError(currentCohortIndex, `${field}Error`, false, currentAbTest.cohorts[currentCohortIndex].uuid))
        } else {
            context._handleCohortErrorRegistration(new CohortError(currentCohortIndex, `${field}Error`, true, currentAbTest.cohorts[currentCohortIndex].uuid))
        }
    }


    const hasErrorOfType = (errorType) => {
        return context.cohortErrors.filter(cError => cError.cohortIndex === currentCohortIndex
            && cError.errorType === errorType
            && !cError.corrected
        ).length > 0
    }

    const validate = (currentCohortIndex, fieldName, value, callback) => {
        // setTrigger(!trigger);
        callback(currentCohortIndex, fieldName, value);
        if (fieldName === 'id') {
            _validateId();
        } else if (fieldName === 'limit') {
            _validateLimit();
        } else if (fieldName === 'weight') {
            _validateWeight();
        } else if (fieldName === 'parameters') {
            _validateParameters();
        } else if (fieldName === 'limited') {
            _validateLimited();
        }
    }

    const _validateUserGroup = () => {
        const currentUserGroup = currentAbTest.cohorts[currentCohortIndex].userGroup;
        const currentCohort = currentAbTest.cohorts[currentCohortIndex].id

        let withoutDuplicates = true;
// debugger
        Object.keys(cohortUserGroups).forEach(cohort => {
            if (cohort !== currentCohort && cohortUserGroups[cohort] === currentUserGroup && !isEmpty(currentUserGroup)) {
                withoutDuplicates = false;
            }
        })

        _registerErrorIfNotConform(withoutDuplicates, 'userGroup')
    }

    const userGroupDuplicated = (currentUserGroup) => {
        let result = false;
        const allUserGroupUsers = currentAbTest.cohorts.map(c=> c.userGroup? c.userGroup : []);

        outer: for (let i = 0; i < allUserGroupUsers.length; i++) {
            const users = allUserGroupUsers[i];
            if (currentCohortIndex !== i) {
                debugger
                for (let user of users) {
                    if (currentUserGroup && currentUserGroup.includes(user)) {
                        result = true;
                        break outer;
                    }
                }
            }
        }
        context.setDuplicatedUserGroupError(result);

        return result;
    }

    const isLimited = () => {
        return currentAbTest.cohorts[currentCohortIndex].hasOwnProperty("limited") ? currentAbTest.cohorts[currentCohortIndex].limited : currentAbTest.cohorts[currentCohortIndex].limit != '-1'
    }

    const setCohortUsers = (value) => {
        const newCohortUserGroups = {...cohortUserGroups};
        newCohortUserGroups[currentAbTest.cohorts[currentCohortIndex].id] = value;
        setCohortUserGroups(newCohortUserGroups)
    }

    return <Grid item xs={12}>
        <Grid item xs={12} container spacing={1}>
            <Grid Grid item xs={12}>
                <TextField
                    label={'Cohort Id'}
                    fullWidth
                    value={currentAbTest.cohorts[currentCohortIndex].id}
                    error={hasErrorOfType('idError')}
                    name={'id'}
                    onChange={(e) => {
                        validate(currentCohortIndex, e.target.name, e.target.value, context.handleCohortParameterChange)}
                    }
                >
                </TextField>
            </Grid>

            <Grid Grid item xs={2}>
                <FormControlLabel
                    control={<Checkbox />}
                    label="Active"
                    name='active'
                    checked={currentAbTest.cohorts[currentCohortIndex].active}
                    onChange={(e) =>
                        validate(currentCohortIndex, e.target.name, e.target.checked, context.handleCohortParameterChange)
                     }
                />
            </Grid>
            <Grid Grid item xs={2}>
                <FormControlLabel
                    control={<Checkbox />}
                    label="Limited"
                    name='limited'
                    checked={isLimited()}
                    onChange={(e) =>
                        validate(currentCohortIndex, e.target.name, e.target.checked, context.handleCohortParameterChange)}
                />
            </Grid>

            <Grid Grid item xs={4}>
                <TextField
                    label={'Size Limit'}
                    fullWidth
                    value={currentAbTest.cohorts[currentCohortIndex].limit}
                    error={hasErrorOfType('limitError')}
                    name={'limit'}
                    disabled={!isLimited()}
                    onChange={(e) => {
                        validate(currentCohortIndex, e.target.name, e.target.value, context.handleCohortParameterChange)}
                    }
                >
                </TextField>
            </Grid>
            <Grid Grid item xs={4}>
                <TextField
                    label={'Weight'}
                    fullWidth
                    value={currentAbTest.cohorts[currentCohortIndex].weight}
                    error={hasErrorOfType('weightError')}
                    name={'weight'}
                    onChange={(e) => {
                        validate(currentCohortIndex, e.target.name, e.target.value, context.handleCohortParameterChange)}
                    }
                >
                </TextField>
            </Grid>
            <Grid Grid item xs={12}>
                {userGroups && <Autocomplete
                    multiple
                    disableClearable={true}
                    options={userGroups ? userGroups : []}

                    value={currentAbTest.cohorts[currentCohortIndex].userGroup ? currentAbTest.cohorts[currentCohortIndex].userGroup : []}

                    onChange={(e, value) => {
                        setCohortUsers(value)
                        validate(currentCohortIndex, 'userGroup', value, context.handleCohortParameterChange)}
                    }
                    renderInput={(params) => (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                                {...params}
                                id="userGroup"
                                name="userGroup"
                                variant="outlined"
                                label="User Group Id"
                                error={userGroupDuplicated(currentAbTest.cohorts[currentCohortIndex].userGroup)}
                                style={{ flex: 1 }}
                            />
                            <Tooltip title="Принудительно распределить игроков из указанных User Group в эту когорту">
                                <IconButton>
                                    <InfoOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )}
                >
                </Autocomplete>}
            </Grid>

            <Grid item xs={12} style={{paddingTop: 3}} display={'flex'} justifyContent={'center'}>
                <span style={{textDecoration: 'underline dotted', cursor: 'pointer'}}
                      onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}>
                    advanced settings
                </span>
            </Grid>
            {showAdvancedSettings && <Fragment>
                <Grid Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={'Parameters'}
                        minRows={3}
                        multiline
                        error={hasErrorOfType('parametersError')}
                        name={'parameters'}
                        value={currentAbTest.cohorts[currentCohortIndex].parameters}
                        onChange={(e) => validate(currentCohortIndex, e.target.name, e.target.value, context.handleCohortParameterChange)}
                    >

                    </TextField>

                </Grid>
            </Fragment>}

        </Grid>
    </Grid>
}