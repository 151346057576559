import {Fragment} from "react";
import {Box} from "@mui/material";

export default function JsonViewer(props) {


    return (
        <Fragment>
            <Box maxHeight={'50vh'} overflow={'scroll'}>
                <pre>{JSON.stringify(props.json, null, 2)}</pre>

            </Box>
        </Fragment>
    )
}