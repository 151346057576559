import {Box, Dialog, DialogContent, DialogTitle, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function CommonModal(props) {
    return (
        <Dialog fullWidth={true} open={props.open} onClose={() => props.handleDialogClose()}>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}> {props.title}</Box>
                    <Box>
                        <IconButton onClick={() => props.handleDialogClose()}>
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>

            {props.content == undefined ? props.children : props.content}

        </Dialog>
    )
}