import {
    Box,
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, Radio,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {
    useGetBundleHistoryQuery,
    useGetPlatformsInfoQuery, useRollbackAllBundleMutation,
    useRollbackBundleMutation
} from "../../core/reducers/campaign";
import Loading from "../Loading";
import clipboardCopy from "clipboard-copy";
import {formatdate} from "../../constants";
import {Alert, LoadingButton} from "@mui/lab";
import {useContext, useState} from "react";
import {SnackBarContext} from "../../core/context/snackBarProvider";
import RollbackResponseDialog from "./RollbackResponseDialog";

export default function CampaignBundleRollbackDialog({bundle, selectedBundle, open, setOpen, env}) {
    const useSnackBars = () => useContext(SnackBarContext);
    const {addAlert} = useSnackBars();

    const {data, isLoading, isError, error} = useGetBundleHistoryQuery({env, bundle}, {skip: !open});

    const [selectedValue, setSelectedValue] = useState({
        id:bundle + selectedBundle.item.generation.generation + selectedBundle.item.generation.bundleVersion + selectedBundle.item.generation.clientVersion.version,
        data: {
            "bundle": bundle,
            "generation": selectedBundle.item.generation.generation,
            "version": selectedBundle.item.generation.bundleVersion,
            "clientVersion": selectedBundle.item.generation.clientVersion.version,
            "environment": env
        }
    })

    const [rollback, rollbackResponse] = useRollbackBundleMutation();
    const [rollbackAll, rollbackAllResponse] = useRollbackAllBundleMutation();

    const [responseOpen, setResponseOpen] = useState(false)

    const [rollbackError, setRollbackError] = useState({
        message:"", color:""
    })

    const handleChange = (item) => {
        setSelectedValue({
            'id': getKey(item),
            'data': {
                "bundle": item.bundleId,
                "generation": item.generation,
                "version": item.bundleVersion,
                "clientVersion": item.clientVersion.version,
                "environment": env
            }
        })
    }

    const handleOpenResponseDialog = (open) => {
        setResponseOpen(open)
    }

    const getKey = (item) => {
        return item.bundleId + item.generation + item.bundleVersion + item.clientVersion.version
    }

    const handleRollbackAll = async () => {
        let data = selectedValue.data
        setRollbackError({color: '', message: ''})

        const response = await rollbackAll(data)
        setResponseOpen(true)

        if (response.hasOwnProperty('data'))
            addAlert({text: 'Saved', type: 'success'})
        else
            addAlert({text: 'Error', type: 'error'})
    }
    const handleRollbackThis = async () => {
        let data = selectedValue.data
        setRollbackError({color: '', message: ''})

        const response = await rollback(data)
        console.log("response")
        console.log(response)
        setRollbackError({color: response.data.error?"error":"success", message: response.data.message})

        if (response.hasOwnProperty('data'))
            addAlert({text: 'Saved', type: 'success'})
        else
            addAlert({text: 'Error', type: 'error'})
    }

    return (
        <><Dialog
            maxWidth={'xl'}
            onClose={() => setOpen(false)} open={open}>
            <DialogTitle>Bundle {bundle} generation {selectedBundle.item.generation.generation} history</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => setOpen(false)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon/>
            </IconButton>
            <DialogContent>

                {rollbackError.message!='' && <Alert severity={rollbackError.color}>{rollbackError.message}</Alert>}

                <Loading isLoading={isLoading} isError={isError} error={error}>
                    {data != undefined &&
                        data.generationHistoryMap.hasOwnProperty(selectedBundle.item.generation.generation) ?
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Client version</TableCell>
                                    <TableCell>Bundle version</TableCell>
                                    <TableCell>Branches</TableCell>
                                    <TableCell>Created At</TableCell>
                                    <TableCell>Platforms</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.generationHistoryMap[selectedBundle.item.generation.generation].map((item, key) => <TableRow>
                                <TableCell>
                                    <Radio
                                        checked={selectedValue.id === getKey(item)}
                                        onChange={() => handleChange(item)}
                                        value={getKey(item)}
                                        name="radio-buttons"
                                    />
                                </TableCell>
                                <TableCell>{item.clientVersion.version}</TableCell>
                                <TableCell>{item.bundleVersion}</TableCell>
                                <TableCell>
                                    <div>Main {item?.data?.repositoryBranch}</div>
                                    <div>Content {item?.data?.contentBranch}</div>
                                </TableCell>
                                <TableCell>{formatdate(item?.data?.date)}</TableCell>
                                {/*<TableCell>{Object.keys(item?.data?.platformToCatalogFile).map(index =>*/}
                                {/*    <div><nobr>{index}: {item?.data?.platformToCatalogFile[index]==''?'false':'true'}</nobr></div>*/}
                                {/*)}</TableCell>*/}
                                <TableCell>{Object.keys(selectedBundle.item?.platforms).map(key => {
                                    return <Box>{key} : {selectedBundle.item?.platforms[key].toString()}</Box>
                                })}</TableCell>
                            </TableRow>)}
                            </TableBody>
                        </Table>
                        : <Alert severity={'info'}>no data for generation {selectedBundle.item.generation.generation}</Alert>
                    }
                </Loading>
            </DialogContent>
            <DialogActions>

                {(data != undefined &&
                    data.generationHistoryMap.hasOwnProperty(selectedBundle.item.generation.generation)) &&
                    <><LoadingButton variant="contained"
                                     loading={rollbackAllResponse.isLoading}
                                     color={"error"}

                                     onClick={handleRollbackAll}
                                     disabled={
                                         selectedValue.id==''
                                     }

                    >
                        Rollback ALL env
                    </LoadingButton>
                        <LoadingButton variant="contained"
                                       color={"warning"}
                                       disabled={
                                           selectedValue.id==''

                                       }
                                       loading={rollbackResponse.isLoading}
                                       onClick={handleRollbackThis}
                        >
                            Rollback {env}
                        </LoadingButton></>
                }
                <Button variant="contained"
                        onClick={setOpen}>
                    Close
                </Button>

            </DialogActions>
        </Dialog>
            {(rollbackAllResponse.data!=undefined&&responseOpen)&&<RollbackResponseDialog response={rollbackAllResponse.data.responses} setOpen={handleOpenResponseDialog} open={responseOpen}></RollbackResponseDialog>}
        </>
    );
}