import api from "../api/base-api"

const campaignTypeApi = api.injectEndpoints({
        endpoints: (build) => ({
            getAllTournaments: build.query({
                query: (data) => {
                    return {
                        'url':'/admin/tournament/getAll',
                        env:data.env
                    }
                },

                providesTags: ['tournaments']
            }),

            saveTournament: build.mutation({
                query: (data) => {
                    return {
                        url: `/admin/tournament/add`,
                        body: data.data,
                        method: "POST",
                        env:data.env
                    }
                },
                invalidatesTags: ['tournaments']
            })
        }),
    }
)

export const {
    useGetAllTournamentsQuery,
    useSaveTournamentMutation,
} = campaignTypeApi;