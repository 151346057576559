import * as React from 'react';
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers'
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import dayjs from "dayjs";

const timezone = require('dayjs/plugin/timezone');
const utc = require('dayjs/plugin/utc');

dayjs.extend(timezone);
dayjs.extend(utc);

export default function BasicDateTimePicker(props) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateTimePicker']}>
                <DateTimePicker
                    disabled={props.disabled}
                    ampm={false}
                    label={props.label}
                    maxDateTime={dayjs('3000-12-31')}
                    onChange={(date) => props.onChange(date, props.name)}
                    value={props.value!=null?props.value.tz('Europe/Moscow'):null}
                    format="YYYY-MM-DD HH:mm:ss"
                />
            </DemoContainer>
        </LocalizationProvider>
    );
}