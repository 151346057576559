import {Backdrop, Box, CircularProgress} from "@mui/material";
import {Alert} from "@mui/lab";
import * as Sentry from "@sentry/react";
import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

const Loading = (props) => {

    const [lastSuccessfulData, setLastSuccessfulData] = useState(null);
    const dispatch = useDispatch();
    const userSession = useSelector((state) => state.data.UserSession);
    const prevPropsRef = useRef();
    const prevProps = prevPropsRef.current;

    useEffect(() => {
        // Update last successful data when data is successfully fetched
        if (!props.isError && props.children) {
            setLastSuccessfulData(props.children);
        }
    }, [props.children, props.isError]);

    useEffect(() => {
        if(props.hasOwnProperty('env')) prevPropsRef.current = props.env;
    }, [props]);

    if (props.isError && props.hasOwnProperty('error') && props.error.status == 401) {
        if(userSession.fetching!=true) dispatch({type: 'AUTH_SELF_REQUEST'})

    }

    const getErrorText = () => {
        console.log(props.error);

        // Early return if props.error is not present
        if (!props.hasOwnProperty("error")) return <Alert severity={"error"}>Network error</Alert>;

        if (props.error.status === 500) {
            let sentry_error_text = `Error 500 ${props.error.status} \n${JSON.stringify(props.error)}`;

            // Check for Cursor error case
            if (props.error?.data?.message?.indexOf('Cursor')>0 &&
                props.hasOwnProperty('env') && props.env === prevProps) {
                if (props.retry) {
                    props.retry();
                }

                Sentry.captureMessage(
                    `Error CURSOR ${props.error.status} \n${JSON.stringify(props.error)}`,
                    Sentry.Severity.Info
                );

                return lastSuccessfulData;
            } else {
                Sentry.captureMessage(sentry_error_text, Sentry.Severity.Warning);
            }
        }

        let error_text = 'Unknown error';
        if (props.error.hasOwnProperty('data')) {
            if (props.error.data === null) {
                switch (props.error.status) {
                    case 401:
                        error_text = 'Not authenticated';
                        break;
                    default:
                        error_text = 'Error ' + props.error.status;
                }
            } else {
                error_text = typeof props.error.data === "object" ? props.error.data.message : "Network error";
            }
        } else if (props.error.hasOwnProperty('error')) {
            switch (props.error.status) {
                case 'FETCH_ERROR':
                    error_text = 'Network connection unavailable.';
                    break;
                default:
                    error_text = props.error.error;
            }
        } else if (props.error.hasOwnProperty('message')) {
            error_text = props.error.message;
        }

        return <Alert severity={"error"}>{error_text}</Alert>;
    };
    
    return (
        <>
            {props.isLoading ? <Box sx={props.display ? {display: props.display} : {display: 'flex'}}>
                    <CircularProgress/>
                </Box> :
                <>
                    {props.isError ? getErrorText() : <div>{props.children}</div>}
                </>
            }

            {props.isFetching && <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={props.isFetching}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>}
        </>

    )
}

export default Loading