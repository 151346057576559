import React from 'react';

import {TableCell, TableRow} from "@mui/material";
import {useGetDiffQuery} from "../../core/reducers/campaign";

import {formatInTimeZone} from "date-fns-tz";
import Audience from "./Audience";
import Loading from "../Loading";
import dayjs from "dayjs";
import BundlesDiff from "./BundlesDiff";
import {useSelector} from "react-redux";
import {isCampaignMode} from "../Utils/CommonUtils";
import Cohort from "./Abtests/Cohort";
import BundlesTemplatesDiff from "./BundlesTemplatesDiff";

export default function Diff({item, cachedItems, from, to, isTemplate}) {
    const sessionState = useSelector((state) => state.data.SourceMode);
    const appMode = sessionState.mode;
    const isCampaigns = isCampaignMode(appMode);

    const formatdate = (timestamp) => {
        let d = dayjs(timestamp * 1000).utcOffset(180).format('YYYY-MM-DD HH:mm:ss')
        return d;
    }

    return <TableRow>
        <TableCell>
            {item}
        </TableCell>
        <TableCell>
            {formatdate(cachedItems[item].item.distribution.from)}
        </TableCell>
        <TableCell>
            {formatdate(cachedItems[item].item.distribution.till)}

        </TableCell>
        <TableCell>
            <div className="campaign-audience">
                {cachedItems[item].item.audiences.map((audience, index) => (
                    <Audience audience={audience}>A{(index + 1)}: </Audience>
                ))}
            </div>
        </TableCell>
        {!isCampaigns &&
            <TableCell>
                <div className="campaign-audience">
                    { cachedItems[item].item.cohorts && Array.from(new Map(Object.entries(cachedItems[item].item.cohorts)).values()).map((cohort, index) => (
                        <Cohort cohort={cohort} disableCurrentSize = {true}>{cohort.id}: </Cohort>
                    ))}
                </div>
            </TableCell>}

        {isCampaigns&&isTemplate!==true &&
            <TableCell>
                <BundlesDiff
                    to = {to}
                    from={from}
                    item={item}
                />

            </TableCell>}
        {isCampaigns&&isTemplate===true &&
            <TableCell>
                <BundlesTemplatesDiff
                    to = {to}
                    from={from}
                    item={item}
                />

            </TableCell>}
    </TableRow>

}