import api from "../api/base-api"

const templatesApi = api.injectEndpoints({
        endpoints: (build) => ({

            getTemplatesTransferStatus: build.query({
                query: (data) => {
                    return {
                        'url': '/admin/template/transfer/get',
                        env:data.env
                    }
                },
            }),

            getTemplates: build.query({
                query: (data) => {
                    return {
                        url: `/admin/template/search?environment=${data.env}&id=${data.id}`,
                        env: data.env
                    }
                },
            }),
            findTemplateById: build.query({
                query: (data) => `/admin/template/select?environment=${data.ev}&id=${data.id}`,
                providesTags: (result, error, arg) => {
                    return result
                        ? [{type: 'Template', id:result.id}, 'Template']
                        : ['Template']
                }
            }),
            getAllTemplates: build.query({
                query: (data) => {
                    let query = '';
                    if(data.hasOwnProperty('from')) {
                        query = `&from=${data.from}&till=${data.till}`
                    }
                    return {
                        url: `/admin/template/publisher/context?environment=${data.env}`+query,
                        env: data.env
                    }
                },
                keepUnusedDataFor: 0,
                providesTags: ['TemplateList'],

            }),
            saveTemplate: build.mutation({
                query: (data) => {
                    const {ev, ...body} = data
                    return {
                        url: `/admin/template/save?environment=${ev}`,
                        method: "POST",
                        body: body.body,
                        env: body.env
                    }
                },
                invalidatesTags: ['TemplateList','Template']

            }),
            transferTemplate: build.mutation({
                query: (data) => {
                    const {environment, source, campaign, env} = data

                    return {
                        url: `/admin/template/transfer/transfer?environment=${environment}&source=${source}&campaign=${campaign}`,
                        method: "POST",
                        body: data,
                        env: env
                    }
                },
                invalidatesTags: ['TemplateList']

            }),
            getTemplateDiff: build.query({
                query: (data) => {
                    const {environment, source, campaign, env} = data
                    return {
                        url: `/admin/template/transfer/bundle/diff?environment=${environment}&source=${source}&campaign=${campaign}`,
                        env: env
                    }
                },
                keepUnusedDataFor: 0
            }),
            setTemplateStatus: build.mutation({
                query: (data) => {
                    const {environment, status, id} = data

                    return {
                        url: `/admin/template/status?environment=${environment}&id=${id}&status=${status}`,
                        method: "POST",
                        body: data,
                        env: data.environment
                    }
                }
            }),
            checkTemplateTransferStatus: build.query({
                query: (data) => {

                    return {
                        url: `/admin/template/transfer/check?transfer_id=${data.transferId}`,
                        method: "GET",
                        env:data.env
                    }
                }
            }),
            getTemplateParameters: build.query({
                query: (data) => {
                    return {
                        url: `/admin/campaign/parameters?environment=${data.env}`,
                        env: data.env
                    }
                },
                providesTags: ['parameters'],
                transformResponse: (response) => {
                    return response.sort((a, b) => a.parameterName.localeCompare(b.parameterName)).sort((a, b) => (b.isMain - a.isMain));
                }
            }),
        }),
    }
)

export const {

    useGetTemplateDiffQuery,
    useGetTransferStatusQuery,
    useGetAllParamsQuery,
    useGetAllBundlesQuery,
    useGetBundleHistoryQuery,
    useGetTemplatesQuery,
    useGetAllTemplatesQuery,
    useFindTemplateByIdQuery,
    useRollbackBundleMutation,
    useRollbackAllBundleMutation,
    useSaveTemplateMutation,
    useTransferTemplateMutation,
    useSetTemplateStatusMutation,
    useGetPlatformsInfoQuery,
    useCheckCampaignTransferStatusQuery
} = templatesApi;