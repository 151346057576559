import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Audience from "../Publisher/Audience";

export default function CampaignDetailsDialog({item, open, setOpen}) {

    return (
        <Dialog onClose={() => setOpen(false)} open={open}>
            <DialogTitle>Audience</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => setOpen(false)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon/>
            </IconButton>
            <DialogContent>
                {item.audiences.map((audience, index) => (
                    <Audience key={index} audience={audience}>A{(index + 1)}: </Audience>
                ))}
            </DialogContent>
        </Dialog>
    );
}
