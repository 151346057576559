import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import 'react-json-view-lite/dist/index.css';
import ReactJson from "react-json-view";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {getEnvName} from "../Utils/CommonUtils";
import clipboardCopy from "clipboard-copy";
import {SnackBarContext} from "../../core/context/snackBarProvider";
import {useContext} from "react";

export default function CampaignJsonDialog({item, open, setOpen}) {

    const useSnackBars = () => useContext(SnackBarContext);
    const {addAlert} = useSnackBars();

    return (
        <Dialog
            maxWidth={'lg'}
            height="500px"
            onClose={() => setOpen(false)} open={open}>

                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    JSON
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent>
                    <ReactJson
                        theme={'bright:inverted'}
                        src={item}
                        iconStyle={'square'}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        enableClipboard={false}
                    />
                </DialogContent>
            <DialogActions>

                <Button variant="outlined"
                        onClick={() => setOpen(false)}>
                    Close
                </Button>

                <Button
                    variant="contained"
                    onClick={() => {
                        clipboardCopy(JSON.stringify(item));
                        addAlert({text: 'copied', type: 'success'})
                        //setOpen(false)
                    }}>
                    Copy JSON
                </Button>
            </DialogActions>

        </Dialog>
    );
}
