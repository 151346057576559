import {
    Button, Checkbox,
    DialogActions,
    DialogContent,
    FormControlLabel,
    FormHelperText,
    Grid,
    MenuItem,
    TextField,
} from "@mui/material";
import CommonModal from "../CommonModal";
import {useContext, useState} from "react";
import {containsRestrictedChars, isEmpty} from "../../Utils/CommonUtils";
import MultipleSelect from "../../Publisher/MultipleSelect";
import {useSaveEnvironmentMutation} from "../../../core/reducers/environments";
import {LoadingButton} from "@mui/lab";
import {SnackBarContext} from "../../../core/context/snackBarProvider";

export default function ModalComponent(props) {
    const useSnackBars = () => useContext(SnackBarContext);
    const {addAlert} = useSnackBars();

    const [saveEnvironment, saveEnvironmentResponse] = useSaveEnvironmentMutation();

    const [errors, setErrors] = useState([])

    const [errorMessage, setErrorMessage] = useState('');

    const handleSave = async () => {
        if (isEmpty(props.currentEnvironment.name)) {
            setErrorMessage('Environment name should not be empty')
        } else if (!props.update && props.environmentsList.indexOf(props.currentEnvironment.name)>=0) {
            setErrorMessage('This name is already used')
        } else if (containsRestrictedChars(props.currentEnvironment.name, [' '])) {
            setErrorMessage('Environment name should not contain spaces')
        }
        else {
            let response = await saveEnvironment(props.currentEnvironment);
            if (response.hasOwnProperty('data') && response.data.hasOwnProperty('name')) {
                addAlert({text: 'Saved', type: 'success'})
                props.handleDialogClose();

            } else {
                addAlert({text: 'Error', type: 'error'})
            }

        }
    }

    const handleChangeEnvironment = (e) => {
        let newEnvironment = {...props.currentEnvironment}
        newEnvironment[e.target.name] = e.target.value;
        props.setCurrentEnvironment(newEnvironment)
    }
    const handleChangeHasCreator = (e) => {
        props.setCurrentEnvironment({
            ...props.currentEnvironment,
            hasCreator: !props.currentEnvironment.hasCreator
        })
    }
    const handleChangeReserved = (e) => {
        props.setCurrentEnvironment({
            ...props.currentEnvironment,
            reserved: !props.currentEnvironment.reserved
        })
    }

    const handleChangeEnvironmentAllowedSources = (data) => {
        let newEnvironment = {...props.currentEnvironment}
        newEnvironment["allowedSources"] = data; //e.target.value.split(',').map(i => i.trim());
        props.setCurrentEnvironment(newEnvironment)
    }


    let getContent = () => {
        return (
            <>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField fullWidth
                                       disabled={props.update}
                                       name="name"
                                       label="Name"
                                       value={props.currentEnvironment.name ? props.currentEnvironment.name : ""}
                                       error={!isEmpty(errorMessage)}
                                       onChange={handleChangeEnvironment}>
                                {props.id}
                            </TextField>
                            <FormHelperText style={{color: "red"}}>
                                {errorMessage}
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={props.currentEnvironment?.hasCreator == true}
                                        onChange={handleChangeHasCreator}
                                        name="hasCreator"
                                    />
                                }
                                label="Has creator"
                            />

                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={props.currentEnvironment?.reserved == true}
                                        onChange={handleChangeReserved}
                                        name="Reserved"
                                    />
                                }
                                label="Reserved"
                            />

                        </Grid>
                        <Grid item xs={4}>

                            <TextField fullWidth
                                       name="reservedPriority"
                                       label="Reserved priority"
                                       value={props.currentEnvironment.reservedPriority !== undefined ? props.currentEnvironment.reservedPriority : '0'}
                                       onChange={handleChangeEnvironment}>
                                {props.id}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            {/*<TextField fullWidth*/}
                            {/*           name="allowedSources"*/}
                            {/*           helperText='Comma separated array'*/}
                            {/*           label="Allowed sources"*/}
                            {/*           value={props.currentEnvironment.allowedSources ? props.currentEnvironment.allowedSources : ""}*/}
                            {/*           onChange={props.handleChangeEnvironmentAllowedSources}>*/}
                            {/*    {props.id}*/}
                            {/*</TextField>*/}

                            <MultipleSelect label={"Allowed sources"}
                                            labelId={"Allowed sources"}
                                            items={props.environmentsList.filter(item => item !== props.currentEnvironment.name)}
                                            defaultSelectedItems={props.currentEnvironment.allowedSources?props.currentEnvironment.allowedSources:[]}
                                            setSelectedItems={handleChangeEnvironmentAllowedSources}/>

                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={saveEnvironmentResponse.isLoading} variant={"contained"} onClick={handleSave}>
                        Save
                    </LoadingButton>
                </DialogActions>
            </>
        )
    }
    return (
        <>
            <CommonModal
                open={props.open}
                handleDialogClose={props.handleDialogClose}
                title={props.title}
                content={getContent()}
            />
        </>
    )
}