import {Fragment} from "react";
import {Box, Divider, Grid, List, ListItemButton, Typography} from "@mui/material";
import dayjs from "dayjs";
import {getAudienceSection, getCohortsSection, getListItem} from "../../CommonSummary";

export default function SummaryStep(props) {

    const timezone = require('dayjs/plugin/timezone');
    const utc = require('dayjs/plugin/utc');

    dayjs.extend(timezone);
    dayjs.extend(utc);

    return (
        <Fragment>
            <Grid container minWidth={875}>
                <Grid xs={12} item>
                    <Typography component="h1" variant="h5">
                        Basic
                    </Typography>
                    <Divider/>
                </Grid>
                <Grid xs={12} item container>
                    <List id='list' style={{width: '100%'}}>
                        {getListItem("ID:", props.currentAbTest.abTestId)}
                        {getListItem("Group:", props.currentAbTest.groupId)}
                        {getListItem("Priority:", props.currentAbTest.priority)}
                        {getListItem("Reassign Cohort:", props.currentAbTest.reassignCohort)}
                        {getListItem("From:",
                            props.currentAbTest.from.utcOffset(180).format('DD.MM.YYYY HH:mm')
                        )}
                        {getListItem("Till:",
                            props.currentAbTest.till.utcOffset(180).format('DD.MM.YYYY HH:mm')
                        )}
                    </List>
                </Grid>

                <Grid xs={12} item>
                    <Typography component="h1" variant="h5">
                        Cohorts:
                    </Typography>
                    <Divider/>
                </Grid>
                {getCohortsSection(props.currentAbTest)}

                <Grid xs={12} item>
                    <Typography component="h1" variant="h5">
                        Audiences
                    </Typography>
                    <Divider/>
                </Grid>
                {props.currentAbTest.audiences.map(audience => {
                        return getAudienceSection(audience, props.currentAbTest);
                    }
                )}
            </Grid>
        </Fragment>
    )
}