import {
    Box,
    Button,
    Container,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {useState} from "react";
import EditIcon from '@mui/icons-material/Edit';
import ViewIcon from '@mui/icons-material/Visibility';

import {
    useDeleteUsersFromGroupMutation, useGetAllUserGroupsInfoQuery,
    useGetAllUserGroupsQuery, useGetAllUserGroupUsersQuery,
    useSaveUserGroupMutation
} from "../../../core/reducers/userGroup";
import ModalComponent from "./ModalComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import CommonDeleteModal from "../CommonDeleteModal";
import {DataGrid} from "@mui/x-data-grid";
import {useSelector} from "react-redux";
import {getEnvName, isAdmin} from "../../Utils/CommonUtils";
import IconButton from "@mui/material/IconButton";
import Loading from "../../Loading";
import FindUsergroupModal from "./FindUsergroupModal";
import UserGroupAmount from "./UserGroupAmount";
import {useSearchParams} from "react-router-dom";

export default function UserGroup() {
    const userSession = useSelector((state) => state.data.UserSession);

    const [searchParams] = useSearchParams();
    const sourceMode = useSelector((state) => state.data.SourceMode);
    const envName = getEnvName('env', sourceMode, searchParams);



    class UserGroup {
        constructor(groupId = '', userIds = [], updated = false) {
            this.groupId = groupId;
            this.userIds = userIds;
            this.updated = updated;
        }
    }


    const [showFindUser, setShowFindUser] = useState(false);
    const [showListUsers, setShowListUsers] = useState(false);
    const [createUserGroup, setCreateUserGroup] = useState(false);
    const [updateUserGroup, setUpdateUserGroup] = useState(false);
    const [currentUserGroup, setCurrentUserGroup] = useState(new UserGroup());
    const [errorMessage, setErrorMessage] = useState('');
    const [openRemoveUserGroupDialog, setOpenRemoveUserGroupDialog] = useState(false);
    const {
        data: userGroups,
        isLoading: userGroupsIsLoading,
        isFetching: userGroupsIsFetching,
        isError: userGroupsIsError,
        error: userGroupsError,
    } = useGetAllUserGroupsInfoQuery({env: sourceMode.isProd?'production':''});
    const {
        data: existingUsersList,
        isSuccess,
        isLoading,
        status
    } = useGetAllUserGroupUsersQuery({
        groupId: currentUserGroup.groupId,
        env: sourceMode.isProd?'production':''
    }, {skip: !currentUserGroup || !currentUserGroup.groupId});

    const rows = userGroups?.groups == undefined ? [] : userGroups?.groups.map(item => ({id: item.groupId, name:item.groupId, amount:item.size}));
    const columns = [
        { field: 'name', headerName: 'Name', width: 150, flex:1 },
        {
            field: 'amount',
            headerName: 'Amount',
            width: 160,

        },
        {
            field: 'view',
            headerName: '',
            width: 70,
            renderCell: (params) => {
                // Assuming isAdmin is available in the component's scope
                    return <IconButton
                        aria-label="edit"
                        onClick={() => handleViewUserGroup(params.row.name)}
                    >
                        <ViewIcon />
                    </IconButton>
            },
            sortable: false,
            filterable: false,
        }
    ];
    if(isAdmin(userSession)) {
        columns.push({
            field: 'edit',
            headerName: '',
            width: 70,
            renderCell: (params) => {
                // Assuming isAdmin is available in the component's scope
                return isAdmin(userSession) && !sourceMode.isProd ? (
                    <IconButton
                        aria-label="edit"
                        onClick={() => handleEditUserGroup(params.row.name)}
                    >
                        <EditIcon />
                    </IconButton>
                ) : null;
            },
            sortable: false,
            filterable: false,
        })

        columns.push({
            field: 'delete',
            headerName: '',
            width: 70,
            renderCell: (params) => {
                // Assuming isAdmin is available in the component's scope
                return isAdmin(userSession) && !sourceMode.isProd ? (
                    <IconButton
                        aria-label="edit"
                        variant={'outlined'}
                        color={'error'}
                        onClick={() => {
                            setOpenRemoveUserGroupDialog(true)
                            setCurrentUserGroup(new UserGroup(params.row.name))
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                ) : null;
            },
            sortable: false,
            filterable: false,
        })
    }

    const [saveUserGroup, saveUserGroupResponse] = useSaveUserGroupMutation();
    const [deleteUsers, deleteUsersResponse] = useDeleteUsersFromGroupMutation();

    const handleCreateUserGroup = () => {
        setCurrentUserGroup(new UserGroup())
        setCreateUserGroup(true);
    }

    const handleEditUserGroup = (index) => {
        setUpdateUserGroup(true)
        setCurrentUserGroup(new UserGroup(index))
    }

    const handleViewUserGroup = (index) => {
        setShowListUsers(true)
        setCurrentUserGroup(new UserGroup(index))
    }

    // if (existingUsersList && !currentUserGroup.updated) {
    //     setCurrentUserGroup(new UserGroup(currentUserGroup.groupId, existingUsersList, true))
    // }

    const handleDialogClose = () => {
        setCreateUserGroup(false);
        setUpdateUserGroup(false);
        setCurrentUserGroup(new UserGroup());
        setErrorMessage('');
    }

    const tableHeader = () => {
        return [
            '№', 'Group name'
        ]
            .map(i => (
                <TableCell align="center">
                    <Typography align="center">{i}</Typography>
                </TableCell>
            ))
    }

    const handleChangeUserGroup = (e) => {
        let newUserGroup = {...currentUserGroup}
        newUserGroup[e.target.name] = e.target.value;
        setCurrentUserGroup(newUserGroup)
    }

    const handleRemoveUserGroup = () => {
        deleteUsers({
            groupId: currentUserGroup.groupId,
            body: {
                users: existingUsersList
            }
        })
    }

    const handleChangeUserIds = (v) => {
        let newUserGroup = {...currentUserGroup}
        // newUserGroup[e.target.name] = e.target.value.split(',').map(i => i.trim());
        newUserGroup.userIds = v;
        setCurrentUserGroup(newUserGroup)
    }

    return (
        <>
            <Container>

                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <h1>User Group Management</h1>

                    <Box>
                        {isAdmin(userSession) && !sourceMode.isProd && <Button sx={{mr:1}} variant="contained"
                                                                               onClick={() => handleCreateUserGroup()}
                        >
                            Add User Group
                        </Button>}
                        <Button variant="contained"
                                onClick={() => setShowFindUser(true)}
                        >
                            Find Group by UserId
                        </Button>
                    </Box>
                </Box>


                <Box pt={0} style={{ flexGrow: 1 }}>
                    <Loading isLoading={userGroupsIsLoading} isError={userGroupsIsError} error={userGroupsError}>
                    </Loading>

                    <DataGrid
                        style={{ height: '75vh' }}
                            rows={rows}
                            columns={columns}
                            pageSize={100}

                        />

                </Box>
                {(createUserGroup || (currentUserGroup.groupId !== '' && updateUserGroup && status === 'fulfilled')) && <ModalComponent
                    title={updateUserGroup ? "Update User Group" : "Create User Group"}
                    open={updateUserGroup || createUserGroup}
                    currentUserGroup={currentUserGroup}
                    handleChangeUserGroup={handleChangeUserGroup}
                    handleChangeUserIds={handleChangeUserIds}
                    handleDialogClose={handleDialogClose}
                    existingUsersList={existingUsersList}
                    isSuccess={isSuccess}
                    // handleSave={handleSave}
                    update={updateUserGroup}
                    setCurrentUserGroup={setCurrentUserGroup}
                    deleteUsers={deleteUsers}
                    saveUserGroup={saveUserGroup}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    userGroups={userGroups}
                />}
                <CommonDeleteModal
                    title={`Delete User group '${currentUserGroup.groupId}'?`}
                    open={openRemoveUserGroupDialog && status === 'fulfilled'}
                    close={() => setOpenRemoveUserGroupDialog(false)}
                    removeEnvironment={handleRemoveUserGroup}
                    currentEnvName={currentUserGroup.groupId}
                />
                {showFindUser && <FindUsergroupModal setShowFindUser={setShowFindUser} />}
                {showListUsers && <UserGroupAmount existingUsersList={existingUsersList} handleDialogClose={() => setShowListUsers(false)} group={currentUserGroup} />}
            </Container>
        </>
    )

}