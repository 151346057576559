import React, {useEffect, useState, useRef} from 'react';
import Loading from "../../Loading";
import {sortCampaign} from "../../Utils/CommonUtils";
import {CampaignItemContext} from "../../../core/context/context";
import {Alert} from "@mui/lab";
import {useGetAllABTestsQuery} from "../../../core/reducers/abtest";
import AbtestsGroup from "./AbtestsGroup";
import {Card} from "@mui/material";


export default function ABTestFrame({
                                          env,
                                          onSelectItem,
                                          filterText,
                                          selectedList,
                                          filterStatuses,
                                          rightPanel,
                                          createDisabled,
                                          dateFilter

                                      }) {


    const [showAllGroups, setShowAllGroups] = useState(null);
    const [showRainbow, setShowRainbow] = useState(window.localStorage.getItem("show_rainbow_" + env) == null ? false : window.localStorage.getItem("show_rainbow_" + env) == 'true')


    const query = {}
    query.env = env
    if(dateFilter!=undefined && dateFilter.from!= '' && dateFilter.to != '') {

        query.from = Math.round(new Date(dateFilter.from).setHours(0,0,0,0)/1000);
        query.till = Math.round(new Date(dateFilter.till).setHours(23,59,59,999)/1000);

    }

    const {
        data: abtests,
        isLoading,
        isError,
        isFetching,
        error
    } = useGetAllABTestsQuery(query, {
        pollingInterval: 5000,
    });

    const abtestsByType = abtests == undefined ? [] : abtests.abtests.reduce((acc, abtest) => {
        const type = abtest.type;

        if (filterText !== '' && abtest.id.toLowerCase().indexOf(filterText.toLowerCase()) < 0) return acc;

        if (filterStatuses !== undefined && filterStatuses.length > 0) {
            if (!filterStatuses.includes(abtest.status)) return acc;
        }

        if (!acc[type]) {
            acc[type] = [];
        }
        acc[type].push(abtest);

        return acc;
    }, {});

    const getExpandTitle = () => {
        if(showAllGroups == true) return "expand all"
        return "collapse all"
    }

    const getRainbowTitle = () => {
        if(showRainbow == false) return "rainbow"
        return "de-rainbow"
    }



    const prevPropsRef = useRef();
    const prevProps = prevPropsRef.current;
    useEffect(() => {

        if(prevProps==undefined || (prevPropsRef.current != env && error==undefined && !isLoading && !isFetching)) {
            prevPropsRef.current = env
        }
    }, [env, isLoading, isFetching, error]);

    const isNotError = (error==''||error==undefined) || (error?.data?.message?.indexOf('Cursor')>0 && prevPropsRef.current==env)
    const shouldShowContent = (!isLoading&&prevPropsRef.current==env || (prevPropsRef.current!=env&&!isFetching))&& isNotError &&(( !isLoading && !isError) || (prevPropsRef.current==env))
    const shouldShowLoading = ((error!=''&&error!=undefined) || isLoading || (isError && prevPropsRef.current!=env) || (isFetching && prevPropsRef.current!=env && prevPropsRef.current!=undefined))


    return (
        <div>
            {shouldShowLoading && <Card  sx={{mt:3.5}} severity="info"><Loading env={env} isLoading={isLoading||isFetching} isError={isError} error={error} ></Loading></Card>}

            {shouldShowContent&&Object.keys(abtestsByType).length === 0 && <Alert sx={{mt:3.5}} severity="info">No AB tests</Alert>}
            {shouldShowContent&&Object.keys(abtestsByType).length > 0 && <>

                <div className="collapse-all-link" onClick={() => {setShowAllGroups(!showAllGroups);}}>{getExpandTitle()}</div>
                &nbsp;
                <div className="collapse-all-link" onClick={() => {
                    setShowRainbow(!showRainbow);
                    window.localStorage.setItem("show_rainbow_" + env, !showRainbow)
                }}>{getRainbowTitle()}</div>

                {Object.keys(abtestsByType).sort().map((type) => (
                    <CampaignItemContext.Provider
                        key={type}
                        value={{
                            env,
                            selectedList,
                            onSelectItem,
                            bundles: abtests.bundles,
                            createDisabled,
                            type,
                            types: abtests.types,
                            campaignType: abtests.types.filter(item => item.readableType == type)[0],
                            items: abtestsByType[type].sort(sortCampaign)
                        }}>
                        <AbtestsGroup showRainbow={showRainbow} showAllGroups={showAllGroups} rightPanel={rightPanel} />

                    </CampaignItemContext.Provider>
                ))}

            </>}
        </div>
    );
};
