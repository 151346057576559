import {
    Box,
    Button, Checkbox,
    Container,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {useState} from "react";
import EditIcon from '@mui/icons-material/Edit';
import {
    useGetAllEnvironmentsQuery,
    useRemoveEnvironmentMutation,
    useSaveEnvironmentMutation
} from "../../../core/reducers/environments";
import DeleteIcon from "@mui/icons-material/Delete";
import ModalComponent from "./ModalComponent";
import CommonDeleteModal from "../CommonDeleteModal";
import {containsRestrictedChars, isAdmin, isEmpty} from "../../Utils/CommonUtils";
import Loading from "../../Loading";
import {useSelector} from "react-redux";
import {DataGrid} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";

export default function Environment() {
    const userSession = useSelector((state) => state.data.UserSession);

    class Environment {
        constructor() {
            this.name = "";
            this.reserved = false;
            this.reservedPriority = 0;
            this.allowedSources = [];
            this.hasCreator = true;
        }
    }


    const [createEnvironment, setCreateEnvironment] = useState(false);
    const [updateEnvironment, setUpdateEnvironment] = useState(false);
    const [currentEnvironment, setCurrentEnvironment] = useState(new Environment());
    const {
        data: environments,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error
    } = useGetAllEnvironmentsQuery()


    const sortEnvironments = (rows) => {
        return rows.sort((a, b) => {
            // First, sort by 'reserved' (true values first)
            if (a.reserved && !b.reserved) return -1;
            if (!a.reserved && b.reserved) return 1;

            // Then, sort by 'reservedPriority' (ascending order)
            if (a.reservedPriority > b.reservedPriority) return -1;
            if (a.reservedPriority < b.reservedPriority) return 1;

            // Finally, sort by 'name' alphabetically
            return a.name.localeCompare(b.name);
        });
    }

    const columns = [
        { field: 'name', headerName: 'Name', width: 100, flex:1 },
        {
            field: 'reserved',
            headerName: 'Reserved',
            width: 140,
            renderCell: (params) => (
                <Checkbox checked={params.value} disabled />
            ),
        },
        { field: 'reservedPriority', headerName: 'Reserved Priority', width: 140 },
        { field: 'allowedSources', headerName: 'Allowed Sources', width: 250 },
        {
            field: 'hasCreator',
            headerName: 'Has Creator',
            width: 140,
            renderCell: (params) => (
                <Checkbox checked={params.value} disabled />
            ),
        },
    ];
    if(isAdmin(userSession)) {
        columns.push({
            field: 'edit',
            headerName: '',
            width: 70,
            renderCell: (params) => {
                // Assuming isAdmin is available in the component's scope
                return isAdmin(userSession) ? (
                    <IconButton
                        aria-label="edit"
                        onClick={() => handleEditEnvironment(params.row.name)}
                    >
                        <EditIcon />
                    </IconButton>
                ) : null;
            },
            sortable: false,
            filterable: false,
        })
        columns.push({
            field: 'delete',
            headerName: '',
            width: 70,
            renderCell: (params) => {
                // Assuming isAdmin is available in the component's scope
                return isAdmin(userSession) ? (
                    !params.row.reserved && <IconButton
                    aria-label="edit"
                    variant={'outlined'}
                    color={'error'}
                    onClick={() => handleRemoveEnvironment(params.row.name)}
                >
                    <DeleteIcon />
                </IconButton>
                ) : null;
            },
            sortable: false,
            filterable: false,
        })
    }

    const rows = environments==undefined ? null : sortEnvironments(Object.values(environments).map(item => ({
        "id": item.name,
        "name": item.name,
        "reserved": item.reserved,
        "reservedPriority": item.reservedPriority,
        "allowedSources": item.allowedSources,
        "hasCreator": item.hasCreator
    })));

    const [openRemoveEnvDialog, setOpenRemoveEnvDialog] = useState(false);
    const [currentEnvName, setCurrentEnvName] = useState('');

    const [removeEnvironment, removeEnvironmentResponse] = useRemoveEnvironmentMutation();

    const handleCreateEnvironment = () => {
        setCurrentEnvironment(new Environment())
        setCreateEnvironment(true);
    }

    const handleEditEnvironment = (name) => {
        setCurrentEnvironment(environments[name])
        setUpdateEnvironment(true)
    }

    const handleDialogClose = () => {
        setCreateEnvironment(false);
        setUpdateEnvironment(false);
        setCurrentEnvironment(new Environment());
    }

    const tableHeader = () => {
        return [
            '№', 'Name', 'Reserved', 'Reserved priority', 'Allowed sources', 'Has creator',
        ]
            .map(i => (
                <TableCell align="center">
                    <Typography align="center">{i}</Typography>
                </TableCell>
            ))
    }

    const handleRemoveEnvironment = (name) => {
        setOpenRemoveEnvDialog(true)
        setCurrentEnvName(name)
        // removeEnvironment(name)
    }


    return (
        <>
            <Container style={{  height: '80vh'}}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <h1>Environment Management</h1>
                    {isAdmin(userSession) && (
                        <Button variant="contained" onClick={() => handleCreateEnvironment()}>
                            Add environment
                        </Button>
                    )}
                </Box>
                <Box style={{ flexGrow: 1 }}>
                    <Loading isLoading={isLoading} isError={isError} error={error}>
                        <DataGrid
                            style={{ height: '75vh' }}
                            rows={rows}
                            columns={columns}
                            pageSize={100}

                        />


                    </Loading>
                </Box>
                {(createEnvironment || updateEnvironment) && <ModalComponent
                    title={!updateEnvironment ? 'Create environment' : 'Update environment'}
                    currentEnvironment={currentEnvironment}
                    setCurrentEnvironment={setCurrentEnvironment}
                    open={createEnvironment || updateEnvironment}
                    handleDialogClose={() => handleDialogClose()}
                    update={updateEnvironment}
                    environmentsList={rows.map(obj => obj.name)}
                />}
                {openRemoveEnvDialog&&<CommonDeleteModal
                    title={`Delete environment '${currentEnvName}'?`}
                    open={openRemoveEnvDialog}
                    close={() => setOpenRemoveEnvDialog(false)}
                    removeEnvironment={removeEnvironment}
                    currentEnvName={currentEnvName}
                />}
            </Container>
        </>
    )

}