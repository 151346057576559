import Loading from "../Loading";
import React from "react";
import {useGetDiffQuery} from "../../core/reducers/campaign";

export default function BundlesDiff({to, from, item}) {
    const {
        data: diffs,
        isLoading,
        isError,
        error
    } = useGetDiffQuery({
        environment: to,
        source: from,
        campaign: item,
        env: to
    })

    return <Loading isLoading={isLoading} isError={isError} error={error}>
        <>
            {(diffs !== undefined && diffs.length > 0) && <>
                Generation: <b>{diffs[0].generation}</b> to version: <b>{diffs[0].bundleVersion}</b><br/>
                Client version: <b>{diffs[0].clientVersion.version}</b>
                <br/><br/>
            </>
            }
            {(diffs !== undefined && diffs.length > 1) && <>
                Generation: <b>{diffs[1].generation}</b> to version: <b>{diffs[1].bundleVersion}</b><br/>
                client version: <b>{diffs[1].clientVersion.version}</b>
            </>
            }
            {
                (diffs !== undefined && diffs.length === 0) && <>нет</>
            }
        </>
    </Loading>
}