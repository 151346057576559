import React from "react";
import {Chip} from "@mui/material";

export default function Cohort({cohort, children, disableCurrentSize}) {
    return (
        <div className="audience-item">
            <div className="audience-item__inline">{children}</div>
            <div className="audience-item__inline">
             <Chip size={"small"} label={cohort.active ? "active": "inactive"} color={cohort.active ? "success" : "error"}/>
            </div>
            <div className="audience-item__inline">
                <Chip label={`Weight: ${cohort.weight}`}/>
            </div>
            <div className="audience-item__inline">
                <Chip label={cohort.limit === -1? "No limit": "Size limit: " + cohort.limit}/>
            </div>
            {(cohort.hasOwnProperty('userGroups') && cohort.userGroups!= null && cohort.userGroups.length>0 ) &&
            <div className="audience-item__inline">
                <Chip label={"User group: " + cohort.userGroups.join(', ')}/>
            </div>}

            {!disableCurrentSize && <div className="audience-item__inline__right">
                <Chip size={"small"} label={`Current size: ${cohort.size}`}/>
            </div>}

            <div className={"audience-item__connected"}>
                <div className="audience-item__inline">
                    Connected campaigns:
                    {(cohort.hasOwnProperty("campaigns") && cohort.campaigns.length >0 ) ?
                        cohort.campaigns.map(item => <Chip size={"small"} label={item}/>)
                        : <Chip size={"small"} label={`No`}/>}
                </div>
            </div>


        </div>
    )

}