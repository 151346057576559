import {setInStorage} from "../../components/Utils/CommonUtils";

const types = {
    TYPE_FILTER : "TYPE_FILTER"
}

export const SEARCH_TYPES = {
    ALL : 'All',
    CAMPAIGNS : 'Campaign',
    AB_TESTS : 'Ab Test',
    TEMPLATES : 'Templates'
};

export const initialState = {
    type: SEARCH_TYPES.ALL
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.TYPE_FILTER:
            let value = action?.payload?.data?.type !== undefined ? action.payload.data.type : null
            if (value) {
                setInStorage('searchType', value);
            }
            return {
                ...state,
                type: value ? value : "",
            }
        default:
            return state
    }
}

export class CampaignConfigs {
    constructor(id) {
        this.type = "Campaign";
        this.id = id;
        this.color = 'green';
        this.urlPath = 'campaigns'
    }
}

export class AbTestConfigs {
    constructor(id) {
        this.type = "Ab Test";
        this.id = id;
        this.color = 'blue';
        this.urlPath = 'abtests'
    }
}
export class TemplatesConfigs {
    constructor(id) {
        this.type = "Templates";
        this.id = id;
        this.color = 'orange';
        this.urlPath = 'templates'
    }
}