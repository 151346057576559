import {useCheckCampaignTransferStatusQuery} from "../../../core/reducers/campaign";
import {Box, CircularProgress, Grid, TableCell, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import Loading from "../../Loading";

export default function TransferCheck({transferId,env, setErrorAfterCheck, transferInProgress, setTransferInProgress, index}) {
    const [currentStatus, setCurrentStatus] = useState('')
    const [currentPercent, setCurrentPercent] = useState(0)
    const {data} = useCheckCampaignTransferStatusQuery({transferId:transferId, env:env},
        {pollingInterval: 1000, skip: (currentStatus === 'TRANSFERRED' || currentStatus === 'FAILED')}
    );

    useEffect(() => {
        let newTransferInProgress = index+1 > transferInProgress.length ? [...transferInProgress, ''] : [...transferInProgress];
        if (data && currentStatus !== data.status) {
            setCurrentStatus(data.status);
            if (data.status === 'FAILED') {
                setErrorAfterCheck(true)
                newTransferInProgress[index] = false
                setTransferInProgress(newTransferInProgress)
            } else if (data.status === 'TRANSFERRED') {
                newTransferInProgress[index] = false
                setTransferInProgress(newTransferInProgress)
            }
        }
        if (data && currentPercent != data.loadPercent) {
            setCurrentPercent(100)
        }
    }, [data]);

    return (
        <>
            <Grid container>
                <Grid sx={{ position: 'relative', display: 'inline-flex' }}>
                    <CircularProgress variant="determinate" value={+currentPercent}/>
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            variant="caption"
                            component="div"
                            color="text.secondary"
                        >{`${Math.round(currentPercent)}%`}</Typography>
                    </Box>
                </Grid>
                <Grid sm={8} pt={1} pl={2}><Box>
                    {currentStatus}</Box></Grid>
            </Grid>


        </>
    )
}