import { combineReducers } from 'redux';
import UserSession from '../reducers//session';
import SourceMode from '../reducers//mode';
import DateFilter from '../reducers//dateFilter';
import TypeFilter from '../reducers//typeFilter';


const reducers = {
    UserSession,
    SourceMode,
    DateFilter,
    TypeFilter
};

export default combineReducers({  ...reducers });
