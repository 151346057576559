import {Autocomplete, Button, Chip, DialogActions, DialogContent, FormHelperText, Grid, TextField} from "@mui/material";
import CommonModal from "../CommonModal";
import {useGetAllGroupsForUserQuery, useGetAllUserGroupUsersQuery} from "../../../core/reducers/userGroup";
import {useState} from "react";
import {containsRestrictedChars, isEmpty} from "../../Utils/CommonUtils";
import Loading from "../../Loading";
import {Alert} from "@mui/lab";
import {useSelector} from "react-redux";

export default function FindUsergroupModal(props) {

    const sourceMode = useSelector((state) => state.data.SourceMode);


    const [inputValue, setInputValue] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const {data, isLoading, isFetching, isError, error} = useGetAllGroupsForUserQuery({userId: searchValue, env: sourceMode.isProd?'production':''}, {skip: searchValue==''})

    const handleDialogClose = () => {
        props.setShowFindUser(false)
    }

    return (
        <>
            <CommonModal
                open={true}
                handleDialogClose={handleDialogClose}
                title={"Find User Groups by User Id"}
            >
                <DialogContent>

                    <Grid container alignItems="center" sx={{mb:2}} spacing={2}>
                        <Grid item xs>
                            <TextField
                                name="userId"
                                label="User Id"
                                onChange={(e) => {
                                    setInputValue(e.target.value)
                                }}
                                value={inputValue}
                                fullWidth
                            />
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={() => {
                                setSearchValue(inputValue)
                            }}>
                                Find
                            </Button>
                        </Grid>
                    </Grid>
                    <Loading isLoading={isFetching || isLoading} isError={isError} error={error}>
                        {data!=undefined && <>
                            {data.length == 0 && <Alert severity={"info"}>User is not a member of any User Group</Alert> }
                            {data.length > 0 && data.map(item => <div>{item}</div>)}
                        </>}
                    </Loading>

                </DialogContent>

            </CommonModal>
        </>
    )
}