import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './containers/App';
import {ApiProvider} from "@reduxjs/toolkit/dist/query/react";
import {Provider, useSelector} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import api from "./core/api/base-api";
import {store} from "./core/configureStore";
import EnvironmentProvider from "./containers/EnvironmentProvider";
import {SnackBarProvider} from "./core/context/snackBarProvider";

import {PublicClientApplication, EventType, InteractionRequiredAuthError} from '@azure/msal-browser';
import {configs, getDomainAlias} from './constants'
import {isTokenExpired} from "./components/Utils/CommonUtils";
import * as Sentry from "@sentry/react";

const msalConfig = configs[getDomainAlias()]['msal']

Sentry.setUser({ email: localStorage.getItem("username") });


/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize()
// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

let refreshInterval = null

const activeAccount = msalInstance.getActiveAccount();

const request = {
    account: activeAccount,
    forceRefresh: true,
    refreshTokenExpirationOffsetSeconds: 60000,
    scopes: [
        'user.read'
    ],
    redirectUri: document.location.origin + "/dummy.html",
}
const request_redirect = {
    account: activeAccount,
    forceRefresh: true,
    refreshTokenExpirationOffsetSeconds: 60000,
    scopes: [
        'user.read'
    ],
    redirectUri: document.location.origin + "/",
}


const runRefreshToken = async () => {
    let token = localStorage.getItem('azureToken')
    //if (!store.getState().data.UserSession.authenticated || !token || isTokenExpired(token)) {

    const tokenResponse = await msalInstance.acquireTokenSilent(request).then((data) => {
        if(localStorage.getItem("azureToken") != data.idToken) {
            console.error("TOKEN CHANGED")
        }
        store.dispatch({
            type: 'AUTH_SELF_SUCCESS', payload: {
                data: data.account.idTokenClaims.roles,
                token: data.idToken,
                email: data.account.username
            }
        });
        localStorage.setItem("azureToken", data.idToken)
        localStorage.setItem("username", data.account.username)
    }).catch(async (e) => {
        console.log('refresh error')
        console.log(e)
        console.log(JSON.stringify(e))

        if (e instanceof InteractionRequiredAuthError) {
            try {

                let response = await msalInstance.acquireTokenPopup(request);
                Sentry.captureMessage(
                    `Token acquireTokenPopup ${JSON.stringify(response)}`,
                    Sentry.Severity.Info
                )
                //document.location.reload()
            } catch (e) {
                console.log("POPUP ERROR")
                if(e.errorCode=='popup_window_error') {

                    //alert('You have blocked popup windows, it is required for authentication')
                    const interactiveResponse = await msalInstance.acquireTokenRedirect(request_redirect);

                }

                console.log(e)
            }
        } else {
            store.dispatch({ type: 'AUTH_SELF_FAILURE', payload: {error: ''} });
            let error_text = JSON.stringify(e)
            Sentry.captureMessage(
                `Token not refreshed ${error_text}`,
                Sentry.Severity.Error
            )
        }


    });

    //}
}

if(activeAccount != null) {
    console.log("INITIAL !!! .getActiveAccount()")
    console.log(activeAccount)
    console.log(msalInstance.getTokenCache().storage)

    if(activeAccount.hasOwnProperty('idTokenClaims')) {
        store.dispatch({
            type: 'AUTH_SELF_SUCCESS', payload: {
                data: activeAccount.idTokenClaims.roles,
                email: activeAccount.username
            }
        });
    }


    Sentry.setUser({ email: activeAccount.username });


    await runRefreshToken()
    refreshInterval = setInterval(runRefreshToken, 60000);
} else {
    // msalInstance.acquireTokenPopup({
    //     ...{},
    //     redirectUri: '/',
    // })
}


// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {

    if (
        (event.eventType === EventType.LOGIN_SUCCESS
            || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
            || event.eventType === EventType.SSO_SILENT_SUCCESS
        ) && event.payload.account
    ) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);

        store.dispatch({
            type: 'AUTH_SELF_SUCCESS', payload: {
                data: account.idTokenClaims.roles,
                token: event.payload.idToken,
                email: account.username
            }
        });
        localStorage.setItem("azureToken", event.payload.idToken)
        localStorage.setItem("username", account.username)

    }
    switch (event.eventType) {
        case EventType.ACQUIRE_TOKEN_FAILURE:
            Sentry.captureMessage(
                `Token refresh failed`,
                Sentry.Severity.Warning
            )
            break;
        case EventType.LOGOUT_SUCCESS:
            store.dispatch({
                type: 'AUTH_LOGOUT'
            })
            clearInterval(refreshInterval)
            localStorage.setItem('azureToken', '')
            break;
    }

    //console.log("event.eventType")
    //console.log(event.eventType)

});



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ApiProvider api={api}>
        <Provider store={store}>
            <BrowserRouter>
                <SnackBarProvider>
                    <EnvironmentProvider>
                        <App instance={msalInstance} key={store.getState().data.UserSession.authenticated&&store.getState().data.UserSession.fetching} />
                    </EnvironmentProvider>
                </SnackBarProvider>
            </BrowserRouter>
        </Provider>
    </ApiProvider>
);