import {
    Autocomplete,
    Button, Checkbox,
    DialogActions,
    DialogContent,
    FormControlLabel,
    FormHelperText,
    Grid,
    TextField,
} from "@mui/material";
import {useSaveCampaignTypeMutation} from "../../../core/reducers/campaignType";
import CommonModal from "../CommonModal";
import {isEmpty} from "../../Utils/CommonUtils";
import {useState, useContext, useEffect} from "react";
import {SnackBarContext} from "../../../core/context/snackBarProvider";
import {Alert, LoadingButton} from "@mui/lab";

import {getDomainAlias} from "../../../constants";

export const CONTENT_TYPE_OPTIONS = ["LoadableContent", "ExtendedContent"]
export const INTERACTION_TYPE_OPTIONS = ["NONE", "TOURNAMENT", "COOPERATION", "CLAN_TOURNAMENT"]
export default function ModalComponent(props) {

    const useSnackBars = () => useContext(SnackBarContext);
    const {addAlert} = useSnackBars();

    const [saveCampaignType, saveCampaignTypeResponse] = useSaveCampaignTypeMutation();


    const [errors, setErrors] = useState({
        id: '',
        readableType: '',
        defaultGenerationClientVersion: '',
    });

    const handleChangeCampaignType = (e) => {
        let newCurrentCampaignType = {...props.currentCampaignType}
        newCurrentCampaignType[e.target.name] = e.target.value;
        props.setCurrentCampaignType(newCurrentCampaignType)
    }

    const handleChangeAbbreviation = (e) => {
        let newCurrentCampaignType = {...props.currentCampaignType}
        let newMetaData = {...newCurrentCampaignType.metaData}
        newMetaData.abbreviation = e.target.value;
        newCurrentCampaignType.metaData = newMetaData;
        props.setCurrentCampaignType(newCurrentCampaignType)
    }

    const handleChangeLink = (e) => {
        let newCurrentCampaignType = {...props.currentCampaignType}
        let newMetaData = {...newCurrentCampaignType.metaData}
        newMetaData.link = e.target.value;
        newCurrentCampaignType.metaData = newMetaData;
        props.setCurrentCampaignType(newCurrentCampaignType)
    }
    const handleChangeVersion = (e) => {
        props.setCurrentCampaignType({
            ...props.currentCampaignType,
            defaultGenerationClientVersion: {
                version: e.target.value
            }
        })
    }

    const handleChangeByName = (name, value) => {
        let newCurrentCampaignType = {...props.currentCampaignType}
        newCurrentCampaignType[name] = value;
        props.setCurrentCampaignType(newCurrentCampaignType)
        console.log("newCurrentCampaignType")
        console.log("newCurrentCampaignType")
        console.log(newCurrentCampaignType)
        console.log(props.currentCampaignType)
    }


    function isValidURL(str) {
        const pattern = new RegExp('^(https?:\\/\\/)' + // protocol
            '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.?)+[a-zA-Z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-zA-Z\\d_]*)?$', 'i'); // fragment locator
        return  !!pattern.test(str);
    }

    useEffect(() => {

        if(!props.update&&(props.currentCampaignType.contentType == ''||props.currentCampaignType.contentType == undefined)) {
            let newCurrentCampaignType = {...props.currentCampaignType}
            newCurrentCampaignType['contentType'] = 'LoadableContent';
            props.setCurrentCampaignType(newCurrentCampaignType)
        }

    }, [props.currentCampaignType])

    const handleSave = async () => {
        let newErrors = {
            id:'',
            readableType: '',
            defaultGenerationClientVersion: '',
            lateDistributionPeriod: '',
            announceDuration: '',
            extraDuration: '' ,
            regexp:'',
            link: ''
        };
        let isValid = true;
        if (!props.update && Object.values(props.types).some(type => type.id === props.currentCampaignType.id)) {
            newErrors.id = 'This Campaign ID already exists';
            isValid = false;
        }


        props.currentCampaignType.readableType = props.currentCampaignType.id
        props.setCurrentCampaignType({
            ...props.currentCampaignType,
            readableType: props.currentCampaignType.id
        })
        // if (isEmpty(props.currentCampaignType.readableType)) {
        //     newErrors.readableType = 'Readable type is required';
        //     isValid = false;
        // }
        if (isEmpty(props.currentCampaignType.id)) {
            newErrors.id = 'Campaign id is required';
            isValid = false;
        }

        if(getDomainAlias() != 'toh' && getDomainAlias() != 'cm') {
            // PascalCase validation for id
            const pascalCaseRegex = /^[A-Z][a-zA-Z]*$/;
            const p_mwe_format = /^P_\d{3}[A-Z]{2}_[A-Z][a-z]*([A-Z][a-z]*)*$/;
            if (!pascalCaseRegex.test(props.currentCampaignType.id) && !p_mwe_format.test(props.currentCampaignType.id)) {
                newErrors.id = 'Campaign id must be in PascalCase format or in format P_###XX_XXXXXXX';
                isValid = false;
            }
        }

        // Additional check for 'cm' domain
        if (getDomainAlias() == 'cm') {
            const snakeCaseRegex = /^[a-z]+(_[a-z\d]+)*$/;
            if (!snakeCaseRegex.test(props.currentCampaignType.id)) {
                newErrors.id = 'Campaign id must be in snake_case format';
                isValid = false;
            }
        }

        if (!isValidRegExp(props.currentCampaignType.regexp)) {
            newErrors.regexp = 'Invalid regular expression';
            isValid = false;
        }
        // if (isEmpty(props.currentCampaignType.defaultGenerationClientVersion?.version)) {
        //     newErrors.defaultGenerationClientVersion = 'Default generation client version is required';
        //     isValid = false;
        // }
        // // Validate defaultGenerationClientVersion with regex
        //
        // const versionRegex = /^\d+\.\d+\.\d+$/;
        //
        // if (!versionRegex.test(props.currentCampaignType.defaultGenerationClientVersion?.version)) {
        //     newErrors.defaultGenerationClientVersion = 'Version must be in format x.x.x';
        //     isValid = false;
        // }

        // Validate integer fields
        const validateInteger = (value, fieldName) => {
            if (isNaN(value) || parseInt(value) < 0) {
                newErrors[fieldName] = `${fieldName.replace(/([A-Z])/g, ' $1').trim()} must be a non-negative integer`;
                isValid = false;
            }
        };

        validateInteger(props.currentCampaignType.lateDistributionPeriod, 'lateDistributionPeriod');
        validateInteger(props.currentCampaignType.announceDuration, 'announceDuration');
        validateInteger(props.currentCampaignType.extraDuration, 'extraDuration');

        if (props.currentCampaignType.metaData !== null && !isEmpty(props.currentCampaignType.metaData.link) && !isValidURL(props.currentCampaignType.metaData.link)) {
            newErrors.link = 'Invalid url';
            isValid = false;
        }


        setErrors(newErrors);

        if (!isValid) {
            return;
        }

        let campaign_data = {...props.currentCampaignType}
        console.log("campaign_data")
        console.log(campaign_data)

        let data_to_save = {
            id: campaign_data.id,
            readable_type: campaign_data.readableType,
            content_type: campaign_data.contentType,
            interaction_type: campaign_data.interactionType,
            regexp: campaign_data.regexp,
            //manifest_id: campaign_data.manifestId,
            limit_late_distribution: campaign_data.limitLateDistribution,
            late_distribution_period_sec: campaign_data.lateDistributionPeriod,
            //default_generation_client_version: campaign_data.defaultGenerationClientVersion?.version,
            announce_duration_hour: campaign_data.announceDuration,
            extra_duration_hour: campaign_data.extraDuration,
            metaData: {
                abbreviation: campaign_data.metaData?.abbreviation,
                link: campaign_data.metaData?.link
            },
            matchmakingTypes: campaign_data.matchmakingTypes
        }

        const response = await saveCampaignType(data_to_save)
        console.log("response")
        console.log(response)



        if (response.hasOwnProperty('data') && response.data.hasOwnProperty('id')) {
            addAlert({text: 'Saved', type: 'success'})
            props.handleDialogClose();
        } else {
            addAlert({text: 'Error', type: 'error'})
        }
    }

    const handleCheckboxChange = (event) => {
        props.setCurrentCampaignType({
            ...props.currentCampaignType,
            lateDistributionPeriod: event.target.checked?props.currentCampaignType.lateDistributionPeriod:0,
            limitLateDistribution: event.target.checked
        })

    };
    const isValidRegExp = (value) => {
        try {
            new RegExp(value);
            return true; // Valid RegExp
        } catch (e) {
            return false; // Invalid RegExp
        }
    };

    console.log("saveCampaignTypeResponse")
    console.log(saveCampaignTypeResponse)

    let getContent = () => {
        return (
            <>
                <DialogContent>
                    {saveCampaignTypeResponse.isError && <Alert sx={{mb:2}} severity={"error"}>{saveCampaignTypeResponse.error.data.message}</Alert>}
                    <Grid container spacing={2}>
                        <Grid item xs={10}>
                            <TextField fullWidth
                                       disabled={props.update}
                                       name="id"
                                       label="ID"
                                       value={props.currentCampaignType.id}
                                       error={!isEmpty(errors.id)}
                                       helperText={errors.id}
                                       onChange={handleChangeCampaignType}>
                                {props.id}
                            </TextField>
                        </Grid>

                        <Grid item xs={8}>
                            <Autocomplete
                                value={props.currentCampaignType.contentType}
                                options={CONTENT_TYPE_OPTIONS}
                                onChange={(e, v) =>
                                    handleChangeByName('contentType', v)}
                                renderInput={params => (
                                    <TextField {...params}
                                               fullWidth
                                               label="Content type"
                                    />
                                )}>

                            </Autocomplete>
                        </Grid>
                        <Grid item xs={5}>
                            <Autocomplete
                                value={props.currentCampaignType.interactionType}
                                options={INTERACTION_TYPE_OPTIONS}
                                onChange={(e, v) =>
                                    handleChangeByName('interactionType', v)}
                                renderInput={params => (
                                    <TextField {...params}
                                               fullWidth
                                               label="Interaction Type"
                                    />
                                )}>

                            </Autocomplete>
                        </Grid>
                        {props.currentCampaignType.interactionType === 'TOURNAMENT' &&
                            <Grid item xs={7}>
                                <Autocomplete
                                    value={props.currentCampaignType.matchmakingTypes || []}
                                    multiple
                                    freeSolo
                                    options={[]}
                                    onChange={(e, v) =>
                                        handleChangeByName('matchmakingTypes', v)}
                                    renderInput={params => (
                                        <TextField {...params}
                                                   fullWidth
                                                   label="Matchmaking Types"
                                                   onKeyDown={(event) => {
                                                       if (event.key === 'Enter' || event.key === ',') {
                                                           event.preventDefault();
                                                           const inputValue = event.target.value.trim();

                                                           if (inputValue) {
                                                               // Split the input value by commas, trim spaces, and filter out empty values
                                                               const newValues = inputValue.split(',')
                                                                   .map(val => val.trim())
                                                                   .filter(val => val && !props.currentCampaignType.matchmakingTypes.includes(val));

                                                               if (newValues.length > 0) {
                                                                   handleChangeByName('matchmakingTypes', [...props.currentCampaignType.matchmakingTypes, ...newValues]);
                                                               }
                                                           }

                                                           // Clear the input field
                                                           event.target.value = '';
                                                       }
                                                   }}
                                        />
                                    )}
                                />
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <TextField fullWidth
                                       name="regexp"
                                       label="Validation Regexp"
                                       value={props.currentCampaignType.regexp}
                                       error={!isEmpty(errors.regexp)}
                                       helperText={errors.regexp}
                                       onChange={handleChangeCampaignType}>
                                {props.regexp}
                            </TextField>
                        </Grid>
                        {/*<Grid item xs={6}>*/}
                        {/*    <TextField fullWidth*/}
                        {/*               name="manifestId"*/}
                        {/*               label="Manifest ID"*/}
                        {/*               value={props.currentCampaignType.manifestId}*/}
                        {/*               onChange={handleChangeCampaignType}>*/}
                        {/*        {props.manifestId}*/}
                        {/*    </TextField>*/}
                        {/*</Grid>*/}



                        {/*<Grid item xs={12}>*/}
                        {/*    <TextField fullWidth*/}
                        {/*               name="defaultGenerationClientVersion"*/}
                        {/*               label="Default generation client version"*/}
                        {/*               value={props.currentCampaignType.defaultGenerationClientVersion?.version}*/}
                        {/*               onChange={handleChangeVersion}*/}
                        {/*               error={!isEmpty(errors.defaultGenerationClientVersion)}*/}
                        {/*               helperText={errors.defaultGenerationClientVersion}*/}
                        {/*    >*/}
                        {/*        {props.manifestId}*/}
                        {/*    </TextField>*/}
                        {/*</Grid>*/}

                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={props.currentCampaignType?.limitLateDistribution == true}
                                        onChange={handleCheckboxChange}
                                        name="limitLateDistribution"
                                    />
                                }
                                label="Limit Late Distribution"
                            />

                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth
                                       name="lateDistributionPeriod"
                                       label="Late Distribution Period (seconds)"
                                       disabled={!props.currentCampaignType?.limitLateDistribution}
                                       value={props.currentCampaignType?.lateDistributionPeriod}
                                       error={!isEmpty(errors.lateDistributionPeriod)}
                                       helperText={errors.lateDistributionPeriod}
                                       onChange={handleChangeCampaignType}>
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth
                                       name="announceDuration"
                                       label="Announce From (hours)"
                                       value={props.currentCampaignType.announceDuration}
                                       error={!isEmpty(errors.announceDuration)}
                                       helperText={errors.announceDuration}
                                       onChange={handleChangeCampaignType}>
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth
                                       name="extraDuration"
                                       label="Extra Time Till (hours)"
                                       value={props.currentCampaignType.extraDuration}
                                       error={!isEmpty(errors.extraDuration)}
                                       helperText={errors.extraDuration}
                                       onChange={handleChangeCampaignType}>
                            </TextField>
                        </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth
                                           name="abbreviation"
                                           label="Code Abbreviation"
                                           value={props.currentCampaignType.metaData ? props.currentCampaignType.metaData.abbreviation: ""}
                                           onChange={handleChangeAbbreviation}>
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth
                                           name="link"
                                           label="Doc link"
                                           value={props.currentCampaignType.metaData ? props.currentCampaignType.metaData.link: ""}
                                           error={!isEmpty(errors.link)}
                                           helperText={errors.link}
                                           onChange={handleChangeLink}>
                                </TextField>
                            </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loading={saveCampaignTypeResponse.isLoading }
                        variant={"contained"}
                        onClick={handleSave}>
                        Save
                    </LoadingButton>
                </DialogActions>
            </>
        )
    }

    return (
        <>
            <CommonModal
                open={props.open}
                handleDialogClose={props.handleDialogClose}
                title={props.title}
                content={getContent()}
            />
        </>
    )
}